import React from "react"

/**
 * Purpose of this Component is to mock our
 * site hitting an error on the page.
 *
 * This is useful for testing if we are properly sending
 * error events to our analytics vendor like Umami.
 *
 * Usage:
 * - open up browser debug console
 * - call function `window.$showMockError()`
 * - the app should render an error page
 */
export function MockErrorTrigger() {
  const [hasMockError, setHasMockError] = React.useState(false)

  React.useEffect(() => {
    window.$showMockError = () => {
      setHasMockError(true)
      return true
    }
  })

  if (hasMockError) {
    throw new Error(`Mock app error`)
  }

  return null
}
