import React from 'react'
import classnames from 'classnames'

import { getComponentOptions } from 'global-content/components'
import { LinkText } from 'components/LinkText'
import { Layout } from 'components/Layout'
import { HtmlText } from 'components/HtmlText'

import styles from './SideNavLink.css'

export const SideNavLink = ({
  active,
  button,
  children,
  className,
  fontSize,
  name,
  onClick,
  id,
  to,
  value,
  isPrivacyPolicy,
  isValid,
}) => {
  const {
    config: {
      activeState,
      hoverState,
    },
    layout = {},
  } = getComponentOptions(`sideNavLink`)

  const hasValue = Boolean(value)
  const shouldShow = Boolean(hasValue && isValid)
  if (shouldShow === false) {
    return null
  }

  return (
    <Layout
      className={styles.container}
      layout={{
        marginBottom: layout.marginBottom,
      }}
    >
      <LinkText
        active={active}
        activeState={activeState}
        aria-label={name}
        button={button}
        className={classnames(className, {
          'optanon-show-settings': isPrivacyPolicy,
        })}
        hoverState={hoverState}
        id={id}
        name={name}
        onClick={onClick}
        style={{
          fontSize,
        }}
        to={to}
        value={value}
      >
        <HtmlText html={value}/>
      </LinkText>
      {children}
    </Layout>
  )
}
