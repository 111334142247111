import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import find from 'lodash/find'
import queryString from 'query-string'

import { usePromotions } from 'hooks/usePromotions'
import { useDetailsPreload } from 'hooks/useDetailsPreload'
import { toListingProduct } from 'models/product'
import { translate } from 'utils/translate'
import { ProductCard } from 'components/ProductCard'
import { updateGoogleAnalytics } from 'services/analytics'
import { SkeletonCard } from 'components/SkeletonCard'
import { ImpressionObserver } from 'components/ImpressionObserver'
import { useEmitClickProductEvent } from '../../../design-system/features/Events/useEmitClickProductEvent'
import { useEmitViewProductEvent } from '../../../design-system/features/Events/useEmitViewProductEvent'
import { algoliaFiltersMap } from 'services/algolia'
import { getIndexName } from 'global-content/algolia'

export const ProductCardContainer = props => {
  const [isVisible, setIsVisible] = React.useState(false)
  return (
    <ImpressionObserver
      onUniqueImpression={() => {
        setIsVisible(true)
      }}
    >
      {isVisible
        ? <ProductCardContainerContent {...props} />
        : <SkeletonCard {...props} />
      }
    </ImpressionObserver>
  )
}

const ProductCardContainerContent = ({
  data,
}) => {
  const {
    analyticsTitle,
    favoritePage,
    cardLocation,
    listLocation,
    onClick,
    position,
    product,
    productCardOptions,
  } = data

  const location = useLocation()
  const getPromotions = usePromotions()
  const activeLanguage = useSelector(state => state.language.active)
  const campaignStateKey = useSelector(state => state.campaigns.stateKey)
  const selectedFilters = useSelector(state => state.listing.selectedFilters)
  const [productContent, setProductContent] = React.useState(getProductContent())
  const detailsPreload = useDetailsPreload()
  const userId = useSelector((state) => state.account.userId)

  React.useEffect(() => {
    setProductContent(getProductContent())
  }, [campaignStateKey])

  const {
    color: { slug },
    sku,
    url,
  } = productContent

  const {
    mainImage,
    secondaryImage,
  } = useMemo(() => {
    return find(productContent.options, { slug }) || {}
  })
  const [selectedColor, setSelectedColor] = React.useState(slug)
  const [selectedImage, setSelectedImage] = React.useState({ mainImage, secondaryImage })
  const [selectedUrl, setSelectedUrl] = React.useState(constructParams(slug))
  const emitClickProductEvent = useEmitClickProductEvent()
  const emitViewProductEvent = useEmitViewProductEvent()

  return (
    <ImpressionObserver
      onUniqueImpression={() => {
        emitViewProductEvent({
          analyticsTitle,
          product,
          userId,
          shoppingSessionId: window.$shoppingSessionId.value,
        })
      }}
    >
      <ProductCard
        favoritePage={favoritePage}
        handleClick={handleClick}
        location={cardLocation}
        onColorChange={onColorChange}
        position={position}
        product={productContent}
        productCardOptions={productCardOptions}
        selectedColor={selectedColor}
        selectedImage={selectedImage}
        url={selectedUrl}
      />
    </ImpressionObserver>
  )

  function getProductContent() {
    const translatedProduct = translate({
      content: product,
      language: activeLanguage,
      returnSomething: true,
    })

    return toListingProduct(translatedProduct, getPromotions)
  }

  function onColorChange(slugName) {
    const colorOption = findOption(productContent.options, slugName)

    const image = {
      mainImage: colorOption.mainImage,
      secondaryImage: colorOption.secondaryImage,
    }

    setSelectedColor(colorOption.slug)
    setSelectedImage(image)
    setSelectedUrl(constructParams(colorOption.slug))
  }

  function handleClick() {
    onClick && onClick()
    detailsPreload({
      color: selectedColor,
      indexName: getIndexName(),
      item: productContent,
      filters: getAssociatedFilters(),
      queryID: productContent.queryID,
    })

    updateGoogleAnalytics(`productClick`, {
      listLocation: analyticsTitle,
      algoliaItem: {
        ...product,
        listLocation,
        position,
      },
    })

    emitClickProductEvent({
      product,
      userId,
      analyticsTitle,
      shoppingSessionId: window.$shoppingSessionId.value,
    })
  }

  function constructParams(colorSlug) {
    // So that the dispatch team can directly check a product when they use a url
    // like search?includeOutOfStock=1&sku=nm_sku177270207

    const colorOption = findOption(productContent.options, colorSlug)

    if (colorOption.productId) {
      const rootUrl = url.split(`/`).slice(0, -2).join(`/`)
      return `${rootUrl}/${colorOption.productSlug}/${colorOption.productId}?color=${colorSlug}`
    }

    if (queryString.parse(location.search).sku) {
      return `${url}?sku=${sku}`
    }

    // Otherwise return the standard URL
    return `${url}?color=${colorSlug}`
  }

  function getAssociatedFilters() {
    const filtersMap = algoliaFiltersMap.get()
    return Object.entries(selectedFilters).flatMap(([key, options]) => {
      const {
        nameInAlgolia,
        filterType,
      } = filtersMap[key]

      if (filterType === `facet`) {
        return options.map(option => `${nameInAlgolia}:${option}`)
      }
      return []
    })
  }
}

function findOption(options, slug) {
  return find(options, color => color.slug === slug)
}
