import React, { useEffect, useState } from 'react'
import get from 'lodash/get'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useGlobalContent } from 'hooks/useGlobalContent'
import { useFilters } from 'hooks/useFilters'
import { useUrlManager } from 'hooks/useUrlManager'
import { getConfigOptions } from 'global-content/config'
import { generateRouteLink } from 'utils'
import { SideNavLink } from 'components/SideNavLink'
import { CategoryNav } from 'components/CategoryNav'
import { getAlgoliaIndex } from 'global-content/algolia'
import { wildcardMatch } from 'utils/wildcardMatch'
import * as algolia from 'services/algolia'

import styles from './SideNav.css'

export const SideNav = ({
  fontSize,
  onClick,
}) => {
  const { batchApply } = useFilters()
  const navMenu = useGlobalContent(`navMenu`)
  const mainNavTree = useGlobalContent(`navTree`)
  const {
    pathname,
    search,
  } = useLocation()
  const urlManager = useUrlManager()
  const levels = pathname.split(`/`).slice(1)

  const navTree = get(navMenu, `sideNavigation.replacement`) || mainNavTree
  const [l1] = levels
  const [links, setLinks] = useState([])

  useEffect(() => {
    if (get(navTree, `l1[${l1}]`)) {
      generateLinks()
    }
  }, [pathname, l1])

  if (get(navTree, `l1[${l1}].l2`)) {
    return (
      <div
        className={styles.sideNav}
        data-testid="side-nav"
      >
        {
          links.map(({
            route,
            path,
            slug,
            isValid,
          }) => (
            <SideNavLink
              active={pathname === path}
              aria-label={route.label}
              button
              fontSize={fontSize}
              isValid={isValid}
              key={slug}
              onClick={() => {
                onClick && onClick()
                handleClick(path)
              }}
              value={route.label}
            />
          )
          )
        }
      </div>
    )
  }

  return (
    <CategoryNav
      fontSize={fontSize}
      levelsToShow={1}
      onClick={onClick}
    />
  )

  function generateLinks() {
    let build = walkTree(navTree.l1, levels)

    algolia.getMultiProducts(build.map(link => link.requestParams)).then((productResponse) => {
      const availableLinks = build.map((link, index) => {
        return {
          ...link,
          isValid: productResponse[index]?.hits?.length > 0,
        }
      })
      setLinks(availableLinks)
    })

    function walkTree(branch, remainingLevels) {
      const parent = levels.slice(0, -1)
      if (remainingLevels.length === 0 || !branch) {
        return makeLink(branch, parent)
      }

      const [currentLevel, ...nextLevels] = remainingLevels
      const nextBranch = branch[currentLevel]

      if (nextBranch) {
        const childLevel = `l${levels.length - nextLevels.length + 1}`
        if (nextBranch[childLevel]) {
          return walkTree(nextBranch[childLevel], nextLevels)
        }
      }

      return makeLink(branch, parent)
    }
  }

  function makeLink(routes, parents) {
    if (!routes) return []
    const shownRoutes = Object.entries(routes).filter((entry) => !entry[1].hide)
    return shownRoutes.map(([slug, route]) => {
      const path = route.path || generateRouteLink(slug, parents)
      return ({
        slug,
        path,
        route,
        requestParams: {
          analytics: false,
          clickAnalytics: false,
          indexName: getAlgoliaIndex(`bestMatch`).indexName,
          options: {
            distinct: 1,
            filters: route.filters,
            hitsPerPage: 1,
          },
        },
      })
    })
  }

  function handleClick(path) {
    const sortOptions = getConfigOptions(`sortOptions`)
    const value = path.split(`?`)
    let appendedParams = {}

    if (value[1]) {
      appendedParams = queryString.parse(decodeURIComponent(value[1]), { arrayFormat: `comma` })
    }

    const sortOption = sortOptions.find(option => option.value === queryString.parse(search).sortBy)
    const remove = [`page`, `featuredProductIds`]

    // some categories don't support a currently selected sortBy
    // i.e. /women/hats?sortBy=featured-boots doesn't make sense
    // this is a check for whether the sortBy should persist between category clicks

    if (sortOption) {
      const {
        onlyAppear,
        doesNotAppear,
      } = sortOption
      if (
        (onlyAppear && !wildcardMatch(value[0], onlyAppear)) ||
      (doesNotAppear && wildcardMatch(value[0], doesNotAppear))
      ) {
        delete appendedParams.sortBy
        remove.push(`sortBy`)
      }
    }

    if (value[0] === pathname) {
      const {
        sortBy,
        query,
        page,
        ...filters
      } = appendedParams

      batchApply({
        filters,
        sortBy,
        query,
        page,
      })
    } else {
      urlManager({
        pathname: {
          value: value[0],
        },
        params: {
          append: appendedParams,
          remove,
        },
      })
    }
  }
}
