import React from 'react'
import classNames from 'classnames'

import { I18n } from 'utils/i18n'
import { useRemapLeftAndRight } from 'hooks/useRemapLeftAndRight'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { IconLabel } from 'components/IconLabel'
import { Svg } from 'components/Svg'
import { Layout } from 'components/Layout'
import { webLabels } from './fixtures'
import styles from './ExpandableSearchInput.css'

const inputMaxLength = 50
const cancelIconSize = 16

export const ExpandableSearchInput = ({
  'aria-label': ariaLabel,
  focusOnMount,
  inputId,
  onBlur,
  onCancel,
  onChange,
  onSubmit,
  positioning = {},
  styling = {},
  'data-testid': testId,
  value,
}) => {
  const {
    expandableInput = {},
    height,
    searchIconInside: searchIconInsideRaw, // falsey | left | right
    searchIconOutside: searchIconOutsideRaw, // falsey | left | right
    showCancelIcon = true,
  } = positioning

  const {
    iconBackgroundColor,
    iconColor,
    inputBackgroundColor,
    inputBorderColor,
    inputBorderRadius,
    inputBorderSize,
    inputFontSize,
    searchIconOutsideSize,
    searchIconInsideSize,
  } = styling

  const {
    initialWidth,
    expandedWidth,
  } = expandableInput

  const [width, setWidth] = React.useState(initialWidth)
  const [focussed, isFocussed] = React.useState(false)
  const [computedHeight, setComputedHeight] = React.useState(height && parseInt(height))
  const [insideIconWidth, setInsideIconWidth] = React.useState(0)
  const inputRef = React.useRef()
  const containerRef = React.useRef()
  useOnClickOutside(containerRef, simulatedBlur, true)
  const searchIconInside = useRemapLeftAndRight(searchIconInsideRaw)
  const searchIconOutside = useRemapLeftAndRight(searchIconOutsideRaw)

  React.useEffect(() => {
    if (focusOnMount && inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current])

  React.useEffect(() => {
    if (expandedWidth) {
      if (focussed) {
        setWidth(expandedWidth)
      } else {
        setWidth(initialWidth)
      }
    }
  }, [focussed])

  return (
    <form
      className={styles.searchForm}
      data-testid={testId}
      onSubmit={handleSubmit}
    >
      {renderOutsideOrder()}
    </form>
  )

  function renderOutsideOrder() {
    if (searchIconOutside === `start`) {
      return (
        <React.Fragment>
          {renderOutsideButton()}
          {renderInside()}
        </React.Fragment>
      )
    }

    if (searchIconOutside === `end`) {
      return (
        <React.Fragment>
          {renderInside()}
          {renderOutsideButton()}
        </React.Fragment>
      )
    }

    return renderInside()
  }

  function renderInside() {
    return (
      <div
        className={styles.holder}
        ref={node => {
          if (node !== null && node.getBoundingClientRect().height) {
            setComputedHeight(node.getBoundingClientRect().height)
          }
        }}
        style={{
          height,
          width: initialWidth, // needs a stable width so siblings don't move
        }}
      >
        <div
          className={classNames(styles.inputWrapper, {
            [styles.absolute]: Boolean(expandedWidth),
          })}
          ref={containerRef}
          style={{
            minWidth: getMinWidth(),
            width,
          }}
        >
          <Layout
            layout={{
              borderColor: inputBorderColor,
              borderSize: inputBorderSize,
              borderRadius: inputBorderRadius,
              backgroundColor: inputBackgroundColor,
            }}
          >
            {renderInsideOrder()}
          </Layout>
        </div>
      </div>
    )
  }

  function renderInsideOrder() {
    if (searchIconInside === `start`) {
      return (
        <React.Fragment>
          {renderInsideButton()}
          {renderInput()}
        </React.Fragment>
      )
    }

    if (searchIconInside === `end`) {
      return (
        <React.Fragment>
          {renderInput()}
          {renderInsideButton()}
        </React.Fragment>
      )
    }

    return renderInput()
  }

  function renderInput() {
    const placeholder = I18n.t(webLabels.placeholder)

    return (
      <React.Fragment>
        <input
          aria-label={ariaLabel || placeholder}
          className={classNames(styles.input, {
            [styles.focussed]: focussed,
            [styles.hasExpandable]: expandedWidth,
          })}
          id={inputId}
          maxLength={inputMaxLength}
          onChange={handleChange}
          onFocus={() => isFocussed(true)}
          placeholder={placeholder}
          ref={inputRef}
          style={{
            ...getPadding(),
            height,
            fontSize: inputFontSize,
          }}
          type="text"
          value={value}
        />
        {showCancelIcon && value &&
          <button
            aria-label={I18n.t(webLabels.clear)}
            className={classNames(styles.close, styles.insideIcon)}
            onClick={e => {
              inputRef.current.focus()
              e.preventDefault()
              onCancel()
            }}
            style={{
              '--closeOffset': getCloseIconOffset(),
            }}
            type="button"
          >
            <Svg
              block
              icon="close"
            />
          </button>
        }
      </React.Fragment>
    )
  }

  function getCloseIconOffset() {
    if (computedHeight) {
      if (searchIconInside === `end`) {
        return `${(computedHeight * 0.25) + insideIconWidth}px`
      }

      return `${computedHeight * 0.25}px`
    }
  }

  function renderInsideButton() {
    const atStart = searchIconInside === `start`
    const atEnd = searchIconInside === `end`

    return (
      <button
        aria-label={I18n.t(webLabels.placeholder)}
        className={classNames(styles.insideIcon, styles.search)}
        onClick={handleSubmit}
        ref={node => {
          if (node !== null && node.getBoundingClientRect().width) {
            setInsideIconWidth(node.getBoundingClientRect().height)
          }
        }}
        style={{
          color: iconColor,
          '--startOffset': atStart && `${computedHeight && computedHeight * 0.25}px`,
          '--endOffset': atEnd && `${computedHeight && computedHeight * 0.25}px`,
        }}
        tabIndex={-1}
      >
        <Svg
          block
          icon="search"
          size={searchIconInsideSize}
        />
      </button>
    )
  }

  function renderOutsideButton() {
    return (
      <button
        onClick={handleButtonClick}
        tabIndex={-1}
        type="button"
      >
        <div
          className={styles.iconWrapper}
          style={{
            color: iconColor,
            backgroundColor: iconBackgroundColor,
            height: computedHeight && computedHeight + (parseInt(inputBorderSize) * 2),
            width: computedHeight && computedHeight + (parseInt(inputBorderSize) * 2),
          }}
        >
          <Svg
            block
            icon="search"
            size={searchIconOutsideSize}
          />
        </div>
        <IconLabel iconLabel={I18n.t(webLabels.placeholder)} />
      </button>
    )
  }

  function handleButtonClick(e) {
    handleSubmit(e)
  }

  function handleSubmit(e) {
    e.preventDefault()
    inputRef.current.focus()

    if (value) {
      onSubmit(value)
    }
  }

  function handleChange(e) {
    onChange(e.target.value)
  }

  function getMinWidth() {
    if (searchIconOutside) {
      return width
    }
  }

  function getPadding() {
    const computedCancelIconSize = showCancelIcon ? cancelIconSize : 0

    if (searchIconInside === `end`) {
      return {
        '--paddingStart': `var(--padding)`,
        '--paddingEnd': `${computedHeight + computedCancelIconSize}px` || undefined,
      }
    }

    if (searchIconInside === `start`) {
      return {
        '--paddingStart': `${computedHeight}px`,
        '--paddingEnd': `${computedCancelIconSize + (computedHeight * 0.25)}px` || undefined,
      }
    }

    if (showCancelIcon) {
      return {
        '--paddingStart': `var(--padding)`,
        '--paddingEnd': `${computedCancelIconSize + (computedHeight * 0.25)}px` || undefined,
      }
    }
  }

  function simulatedBlur() {
    onBlur && onBlur()
    isFocussed(false)
  }
}
