import { submitEmail } from 'state/actions/emailSignup'
import { getMetaOptions } from 'global-content/config'
import { Events } from 'design-system/services/events'

// Callback function to subscribe a user to our internal services
// after a klaviyo subscribe popup form has been submitted
function internalSubscribeApiCallback() {
  window.addEventListener(`klaviyoForms`, function(event) {
    if (event.detail.type === `submit`) {
      const email = event?.detail?.metaData?.$email
      submitEmail({ email })
    }
  })
}

export function klaviyoInitCallback() {
  internalSubscribeApiCallback()
}

export function subscribeBackInStock(props = {}) {
  const {
    emailAddress,
    variantId,
  } = props

  const klaviyoCompanyId = getMetaOptions(`integrations.klaviyo.publicApiKey`)
  if (Boolean(klaviyoCompanyId) === false) {
    return Promise.reject(`Klaviyo is not enabled.`)
  }

  // Note: Send profile to Klaviyo like userLocale
  Events.emitIdentifyUserSession({ userEmail: emailAddress })

  return fetch(`https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=${klaviyoCompanyId}`, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`,
      revision: `2024-07-15`,
    },
    body: JSON.stringify({
      data: {
        type: `back-in-stock-subscription`,
        attributes: {
          profile: {
            data: {
              type: `profile`,
              attributes: {
                email: emailAddress,
              },
            },
          },
          channels: [`EMAIL`],
        },
        relationships: {
          variant: {
            data: {
              type: `catalog-variant`,
              id: `$custom:::$default:::${variantId}`,
            },
          },
        },
      },
    }),
  })
}
