export const getDetailsImages = (details) => {
  const productId = details.product.id
  const levelOptions = details.levelOptions
  const selectedSlugs = details.selectedSlugs
  const colorOptions = levelOptions.color
  const selectedColor = colorOptions
    .find(color =>
      color.productSlug ?
        color.productSlug === `${productId}.${selectedSlugs.color}` :
        color.slug === selectedSlugs.color
    )
  const {
    large: images,
    video: videos,
  } = selectedColor.media

  return {
    images,
    videos,
  }
}