import React from 'react'
import merge from 'lodash/merge'

import {
  HITS_PER_PAGE,
  DESKTOP_KEY,
  TABLET_MEDIUM_KEY,
  TABLET_SMALL_KEY,
} from 'utils/constants'
import { updateGoogleAnalytics } from 'services/analytics'
import { getIndexName } from 'global-content/algolia'
import { getComponentOptions } from 'global-content/components'
import { Grid } from 'components/Grid'
import { ProductCardContainer } from 'components/ProductCardContainer'
import { useDeepCompare } from 'hooks/useDeepCompare'
import { PosterCard } from 'factory-components/PosterCard'
import { Responsive } from 'components/Responsive'
import { SkeletonCard } from 'components/SkeletonCard'
import { ImpressionObserver } from 'components/ImpressionObserver'

export const ProductGrid = ({
  algoliaIndex,
  analyticsTitle,
  autoResponsive = true,
  favoritePage,
  itemsPerRow = 4,
  itemsToShow = HITS_PER_PAGE,
  location,
  logData,
  onItemClick,
  posters = [],
  productCardGap = `2rem`,
  productCardOptions,
  products = [],
  skeleton,
}) => {
  const deepCheck = useDeepCompare(products)
  const indexName = getIndexName(algoliaIndex)
  const [enabledProductImpression, setEnabledProductImpression] = React.useState(false)
  const defaultProductCardOptions = getComponentOptions(`productCardOptions`)
  const combinedProductCardOptions = merge({}, defaultProductCardOptions, productCardOptions)

  React.useEffect(() => {
    if (products?.length && enabledProductImpression && logData) {
      updateGoogleAnalytics(`productImpression`, {
        items: products,
        listLocation: analyticsTitle,
      })
    }
  }, [deepCheck, enabledProductImpression])

  const content = renderContent()

  return (
    <ImpressionObserver onUniqueImpression={() => setEnabledProductImpression(true)}>
      {autoResponsive ? (
        <>
          <Responsive deviceRange={[DESKTOP_KEY, TABLET_MEDIUM_KEY]}>
            <Grid
              columns={itemsPerRow}
              gap={productCardGap}
            >
              {content}
            </Grid>
          </Responsive>
          <Responsive deviceRange={[TABLET_MEDIUM_KEY, TABLET_SMALL_KEY]}>
            <Grid
              columns={3}
              gap={productCardGap}
            >
              {content}
            </Grid>
          </Responsive>
          <Responsive deviceRange={[TABLET_SMALL_KEY]}>
            <Grid
              columns={2}
              gap={productCardGap}
            >
              {content}
            </Grid>
          </Responsive>
        </>) : (
        <Grid
          columns={itemsPerRow}
          gap={productCardGap}
        >
          {content}
        </Grid>
      )
      }
    </ImpressionObserver>
  )

  function renderContent() {
    if (skeleton) {
      return renderSkeleton()
    }

    const items = generateItems()

    return items.slice(0, itemsToShow).map((v, i) => {
      if (v?.data?.component === `posterCard`) {
        return (
          <PosterCard
            items={v.items}
            key={v.id}
            onItemClick={onItemClick}
          />
        )
      }

      // Note: Append array position to component key because
      // sometimes there are products that are duplicated in
      // the returned results from Aloglia. This may happen
      // when items are pinned from an Algolia "custom rule".
      const productCardKey = `${v.objectID}-position-${i}`

      return (
        <ProductCardContainer
          data={{
            analyticsTitle,
            favoritePage,
            indexName: indexName,
            cardLocation: location,
            onClick: onItemClick,
            position: i + 1,
            product: v,
            productCardOptions: combinedProductCardOptions,
          }}
          key={productCardKey}
        />
      )
    })
  }

  function renderSkeleton() {
    const skeletonGrid = []

    for (let i = 0; i < itemsToShow; i++) {
      skeletonGrid.push(
        <SkeletonCard
          data={{
            productCardOptions: combinedProductCardOptions,
            position: i,
          }}
          key={i}
        />
      )
    }

    return skeletonGrid
  }

  function generateItems() {
    const items = [...products]

    posters.forEach(poster => {
      items.splice(poster.data.config.index, 0, poster)
    })

    return items
  }
}
