import React from 'react'
import isNil from 'lodash/isNil'

import { getMetaOptions } from 'global-content/config'
import { Button } from 'factory-components/Button'
import { I18n } from 'utils/i18n'
import { currencyFormatted } from 'utils'
import { InputWithLabel } from 'components/InputWithLabel'

import { webLabels } from './fixtures'
import styles from './FiltersInputRange.css'

export const FiltersInputRange = ({
  availableMax,
  availableMin,
  onInputComplete,
  selected = [],
  usePending,
}) => {
  const [selectedMin, selectedMax] = selected
  const [minVal, setMinVal] = React.useState(``)
  const [maxVal, setMaxVal] = React.useState(``)
  const [err, setErr] = React.useState(``)

  const {
    symbol: currencySymbol,
    stepMultiple,
  } = getMetaOptions(`currency`)

  React.useEffect(() => {
    setMinVal(!isNil(selectedMin) ? selectedMin : ``)
    setMaxVal(!isNil(selectedMax) ? selectedMax : ``)
  }, [selectedMin, selectedMax])

  return (
    <form
      className={styles.inputRange}
      noValidate
      onSubmit={handleSubmit}
    >
      <div className={styles.inputRangeHolder}>
        <InputWithLabel
          id="min"
          label={I18n.t(webLabels.min)}
          min={0}
          onChange={e => {
            const newMin = e.target.value || ``
            setMinVal(newMin)
            usePending && updateFilters(newMin, maxVal)
          }}
          placeholder={currencyFormatted(availableMin, false)}
          prefix={currencySymbol}
          size='small'
          step={stepMultiple > 1 ? stepMultiple : 1}
          type='number'
          value={minVal}
        />
        <div className={styles.inputSeparator}>-</div>
        <InputWithLabel
          id="max"
          label={I18n.t(webLabels.max)}
          min={0}
          onChange={e => {
            const newMax = e.target.value || ``
            setMaxVal(newMax)
            usePending && updateFilters(minVal, newMax)
          }}
          placeholder={currencyFormatted(availableMax, false)}
          prefix={currencySymbol}
          size='small'
          step={stepMultiple > 1 ? stepMultiple : 1}
          type='number'
          value={maxVal}
        />
      </div>
      {!usePending &&
        <Button
          data={{
            content: {
              type: `submit`,
            },
            positioning: {
              buttonHeight: `medium`,
              buttonWidth: `medium`,
            },
            styling: {
              buttonHeight: `large`,
              fontSize: `small`,
              type: `primary`,
            },
          }}
        >
          {I18n.t(webLabels.update)}
        </Button>
      }
      {
        err &&
        <div
          className={styles.inputRangeError}
        >
          {err}
        </div>
      }
    </form>
  )

  function handleSubmit(e) {
    e.preventDefault()
    updateFilters(minVal, maxVal)
  }

  function updateFilters(min, max) {
    const [newMin, newMax] = [min, max].map(num => Number(num) || null)
    if (!isNil(newMax) && newMin > newMax) {
      setErr(I18n.t(webLabels.invalid))
    } else {
      setErr(``)
      onInputComplete([newMin, newMax])
    }
  }
}
