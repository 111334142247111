import React from "react"
import classNames from "classnames"
import { Link } from "components/Link"
import { Heading } from "components/Heading"
import { Layout } from "components/Layout"
import styles from "../ProductCard/ProductCard.css"
import headerStyles from "./ProductCardHeader.css"
import { I18n } from "utils/i18n"
import { webLabels } from "./fixtures"
import { useReveal } from "hooks/useReveal"
import uniqueId from "lodash/uniqueId"
import { LayoutConditional } from "../../../design-system/components"

export function ProductCardHeader({
  label,
  location,
  onClick,
  reveal,
  showLabel,
  showTitle,
  title,
  titleWrap,
  url,
}) {
  const id = uniqueId()
  const {
    reveal: revealState,
    hide,
    toggle,
  } = useReveal({ location })

  // note:
  // pulled from ProductCard so the interior can be reused inside button
  const headerEl = (
    <>
      <LayoutConditional when={showLabel}>
        <Layout
          className={styles.productCardBrandName}
          layout={{
            marginBottom: !showTitle && `xxxxs`,
          }}
        >
          <Heading
            content={{
              html: label,
            }}
            styling={{
              fontSize: `xs`,
              universalColor: true,
            }}
          />
        </Layout>
      </LayoutConditional>
      <LayoutConditional when={showTitle}>
        <Link
          className={styles.title}
          onClick={onClick}
          style={{
            height: titleWrap ? `unset` : undefined,
          }}
          tabIndex={-1}
          title={title}
          to={url}
        >
          <div
            className={classNames({
              [styles.ellipsisWrapper]: !titleWrap,
            })}
            data-testid="product-card-title"
          >
            {title}
          </div>
        </Link>
      </LayoutConditional>
    </>
  )

  if (reveal) {
    return (
      <div
        aria-atomic="true"
        aria-live="polite"
        aria-relevant="text"
        className={headerStyles.buttonContainer}
        data-testid="reveal-designer"
        onMouseLeave={hide}
      >
        <div
          className={classNames(headerStyles.button, {
            [headerStyles.reveal]: revealState,
          })}
        >
          <button
            aria-controls={`#${id}`}
            className={headerStyles.front}
            onBlur={hide}
            onClick={toggle}
            tabIndex="0"
          >
            {I18n.t(webLabels.button)}
          </button>
          <div
            aria-hidden={String(!revealState)}
            className={headerStyles.back}
            data-nosnippet="true"
            data-testid="reveal-designer-link"
            id={id}
          >
            {revealState && headerEl}
          </div>
        </div>
      </div>
    )
  }

  return headerEl
}
