import {
  DETAILS_PRELOAD,
  DETAILS_ADD_MESSAGE,
  DETAILS_REMOVE_MESSAGE,
  ENABLE_CUSTOMISATION,
  GET_PRODUCT,
  POST_PRODUCT_CUSTOMIZATION,
  PRE_SELECT_PRODUCT_CUSTOMISATION,
  NO_PRODUCT,
  UPDATE_SELECTED_OPTION,
  SET_PENDING_QUANTITY,
  UPDATE_MISSING_LEVELS,
  VALIDATE_PRODUCT_CUSTOMISATION,
} from "state/actions"
import { CUSTOMISATION_INITIAL_LOCALSTORAGE_KEY } from "utils/constants"
import { normalizeProductVariations, getGroupProductFallback } from 'state/utils/details'
import { unversionedStorage } from 'utils/storage'
import get from 'lodash/get'
import * as api from 'services/api'
import { reportError } from 'services/reportError'
import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'
import { SENTRY_GENERATED_URL } from 'design-system/features/ProductCustomisation/fixtures'
import { preloadImage } from 'utils/preloadImage'
import { normalizeProduct } from 'state/utils/details'

export const detailsPreload = ({
  indexName,
  product,
  selectedSlugs,
  filters,
  queryID,
}) => ({
  type: DETAILS_PRELOAD,
  payload: {
    indexName,
    product,
    selectedSlugs,
    filters,
    queryID,
  },
})

export const detailsAddMessage = payload => ({
  type: DETAILS_ADD_MESSAGE,
  payload,
})

export const detailsRemoveMessage = payload => ({
  type: DETAILS_REMOVE_MESSAGE,
  payload,
})

export const initProductCustomisation = ({
  productId,
  skuId,
}) => async(dispatch) => {
  const preselectedItems = unversionedStorage.get(CUSTOMISATION_INITIAL_LOCALSTORAGE_KEY) || {}
  try {
    await dispatch({
      type: POST_PRODUCT_CUSTOMIZATION,
      payload: api.postProductCustomisation({
        productId,
        skuId,
        selectedOptions: preselectedItems,
      }).then((response) => {
        if (response.config.hasPreview) {
          return preloadImage(response.cart.display.previewImage.full)
            .then(() => response)
        }
        return response
      }),
    })
  } catch (err) {
    reportError(new ErrorAdditional({
      additional: { productId, step: SENTRY_GENERATED_URL },
      severity: 1,
      title: `Customisation init error`,
      message: err?.message,
      originalError: err,
      tags: {
        [SENTRY_TAGS.SOURCE_FILE]: `src/state/actions/details.js`,
      },
    }))
    console.warn(err)
  }
}

export const getProduct = ({
  callback,
  productId,
  language,
  getPromotions,
  search,
}) => async(dispatch, getState) => {
  try {
    await dispatch({
      type: GET_PRODUCT,
      payload: (
        api.getProduct({ productId, language, getPromotions, search })
          .catch(() => getGroupProductFallback({ productId, language, getPromotions, search }))
          .then(async(product) => normalizeProductVariations({ product, language }))
          .then(product => normalizeProduct({
            product,
            getPromotions,
            search,
          }))
          .then(({
            product,
            skuOptions,
            ...rest
          }) => {
            if (product.customisation) {
              dispatch(enableCustomisation({
                enabled: true,
                loading: true,
              }))
              dispatch(initProductCustomisation({ productId, skuId: skuOptions.sku }))
            } else {
              dispatch(enableCustomisation({
                enabled: false,
                loading: false,
              }))
            }
            return { product, skuOptions, ...rest }
          })
      ),
    })
    callback(getState)
  // Error is already handled by redux middleware.  No need to throw.
  } catch (err) {
    console.warn(err)
  }
}

export const postProductCustomisation = ({
  selectedOptions,
  skuId,
}) => async(dispatch, getState) => {
  const productId = get(getState(), `details.product.id`)
  try {
    await dispatch({
      type: POST_PRODUCT_CUSTOMIZATION,
      payload: api.postProductCustomisation({
        skuId,
        productId,
        selectedOptions,
      }).then((response) => {
        if (response.config.hasPreview) {
          return preloadImage(response.cart.display.previewImage.full)
            .then(() => response)
        }
        return response
      }),
    })
  } catch (err) {
    reportError(new ErrorAdditional({
      additional: { productId, step: SENTRY_GENERATED_URL },
      severity: 1,
      title: `Customisation post error`,
      message: err?.message,
      originalError: err,
      tags: {
        [SENTRY_TAGS.SOURCE_FILE]: `src/state/actions/details.js`,
      },
    }))
    console.warn(err)
  }
}

export const preSelectProductCustomisation = (selection) => async(dispatch) => {
  dispatch({
    type: PRE_SELECT_PRODUCT_CUSTOMISATION,
    payload: selection,
  })
}

export const selectProductCustomisation = (selection) => async(dispatch, getState) => {
  dispatch({
    type: PRE_SELECT_PRODUCT_CUSTOMISATION,
    payload: selection,
  })
  const state = getState()
  const selectedOptions = get(state, `details.customisation.selectedOptions`)
  const skuId = get(state, `details.skuOptions.sku`)
  try {
    await dispatch(postProductCustomisation({
      skuId,
      selectedOptions,
    }))
    const storedCustomisation = unversionedStorage.get(CUSTOMISATION_INITIAL_LOCALSTORAGE_KEY) || {}
    unversionedStorage.set(CUSTOMISATION_INITIAL_LOCALSTORAGE_KEY, {
      ...storedCustomisation,
      [selection.id]: selection.value,
    })
  } catch (err) {
    console.warn(err)
  }
}

export const enableCustomisation = (payload) => ({
  type: ENABLE_CUSTOMISATION,
  payload,
})

export const validateProductCustomisation = payload => ({
  type: VALIDATE_PRODUCT_CUSTOMISATION,
  payload: payload,
})

export const noProduct = () => ({
  type: NO_PRODUCT,
})

export const setPendingQuantity = payload => ({
  type: SET_PENDING_QUANTITY,
  payload,
})

export const updateMissingLevels = payload => ({
  type: UPDATE_MISSING_LEVELS,
  payload,
})

export const updateSelectedDetailOption = ({
  options,
  urlManager,
  getPromotions,
  isValid,
}) => async(dispatch, getState) => {
  await dispatch({
    type: UPDATE_SELECTED_OPTION,
    payload: {
      options,
      getPromotions,
      isValid,
    },
  })

  const state = getState()
  try {
    if (get(state, `details.product.customisation`)) {
      const selectedOptions = get(state, `details.customisation.selectedOptions`)
      const skuId = get(state, `details.skuOptions.sku`)
      await dispatch(postProductCustomisation({
        selectedOptions,
        skuId,
      }))
    }
  } catch (err) {
    console.warn(err)
  }

  urlManager({
    method: `replace`,
    params: {
      append: options,
      remove: [`sku`],
    },
  })
}
