import {
  ACCOUNT_SET_VALUES,
  ACCOUNT_SIGN_OUT,
  ADD_SUGESTED_USERNAME,
} from 'state/actions'

const initialState = {
  isUserSignedIn: false,
  userConfirmed: undefined,
  userEmail: ``,
  userFamilyName: ``,
  userGivenName: ``,
  userId: undefined,
  suggestedUsername: undefined,
  userJwt: ``,
  userName: ``,
  userPhoneNumber: ``,
  userPhoneNumberVerified: false,
}

const account = (state = initialState, action) => {
  switch (action.type) {
  case ACCOUNT_SIGN_OUT:
    return initialState
  case ADD_SUGESTED_USERNAME:
    return {
      ...state,
      suggestedUsername: action.payload,
    }
  case ACCOUNT_SET_VALUES:
    return {
      ...state,
      ...action.payload,
    }
  default:
    return state
  }
}

export default account
