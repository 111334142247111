import { getMetaOptions } from 'global-content/config'
import { Events } from 'design-system/services/events'

export class Authorisation {
  constructor(cognitoIdentifyJS) {
    this.cognitoIdentifyJS = cognitoIdentifyJS
  }

  configureCognito() {
    const cognitoSettings = getMetaOptions(`integrations.cognito`)

    if (cognitoSettings.enabled) {
      const {
        appClientId,
        poolId,
      } = cognitoSettings

      const poolData = {
        UserPoolId: poolId,
        ClientId: appClientId,
      }

      const CognitoUserPool = this.cognitoIdentifyJS.CognitoUserPool
      this.userPool = new CognitoUserPool(poolData)
    }
  }

  currentAuthenticatedUser() {
    return new Promise((resolve, reject) => {
      const user = this.userPool.getCurrentUser()

      if (user) {
        user.getSession(async(err, session) => { // refreshes by default?
          if (err) {
            reject()
          } else {
            const attributes = await new Promise((res, rej) => {
              user.getUserAttributes((e, atts) => {
                if (e) {
                  rej()
                } else {
                  const results = {}

                  for (let attribute of atts) {
                    const {
                      Name,
                      Value,
                    } = attribute
                    results[Name] = Value
                  }
                  res(results)
                }
              })
            })
            resolve({
              user,
              ...session,
              ...attributes,
            })
          }
        })
      } else {
        reject({
          code: `NoCurrentUserException`,
          message: `There is no current user.`,
        })
      }
    })
  }

  signUp({
    username,
    password,
    userAttributes,
    clientMetadata,
  }) {
    return new Promise((resolve, reject) => {
      const callback = (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      }

      this.userPool.signUp(username, password, userAttributes, null, callback, clientMetadata)
    })
  }

  signIn(userName, password, ClientMetadata) {
    const CognitoUser = this.cognitoIdentifyJS.CognitoUser
    const AuthenticationDetails = this.cognitoIdentifyJS.AuthenticationDetails

    const user = new CognitoUser({
      Username: userName,
      Pool: this.userPool,
    })

    const authDetails = new AuthenticationDetails({
      Username: userName,
      Password: password,
      ClientMetadata,
    })

    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: data => {
          resolve(data)
        },
        onFailure: err => {
          reject(err)
        },
      })
    })
  }

  signOut() {
    const user = this.userPool.getCurrentUser()
    if (user) {
      user.signOut()
    }
  }

  changePassword(currentPassword, newPassword) {
    return new Promise((resolve, reject) => {
      this.currentAuthenticatedUser().catch(e => {
        reject(e)
      }).then(({ user }) => {
        user.changePassword(currentPassword, newPassword, (err, result) => {
          if (err) {
            console.error(err)
            reject(err)
          } else {
            resolve(result)
          }
        })
      })
    })
  }

  forgotPassword(userName, clientMetaData) {
    const CognitoUser = this.cognitoIdentifyJS.CognitoUser

    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: userName,
        Pool: this.userPool,
      })

      user.forgotPassword({
        onSuccess: data => {
          resolve(data)
        },
        onFailure: err => {
          reject(err)
        },
        inputVerificationCode: data => {
          resolve(data)
        },
      }, clientMetaData)
    })
  }

  forgotPasswordSubmit(userName, code, newPassword, clientMetaData) {
    const CognitoUser = this.cognitoIdentifyJS.CognitoUser

    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: userName,
        Pool: this.userPool,
      })

      user.confirmPassword(code, newPassword, {
        onSuccess: data => {
          Events.emitForgotPasswordChangeCompleted({ userEmail: userName })
          resolve(data)
        },
        onFailure: err => {
          Events.emitForgotPasswordChangeFailed({
            userEmail: userName,
            errorMessage: err?.message || ``,
          })
          reject(err)
        },
      }, clientMetaData)
    })
  }

  resendSignUp(userName, clientMetaData) {
    const CognitoUser = this.cognitoIdentifyJS.CognitoUser

    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: userName,
        Pool: this.userPool,
      })

      const callback = (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      }

      user.resendConfirmationCode(callback, clientMetaData)
    })
  }

  confirmSignUp(userName, code, clientMetadata) {
    const CognitoUser = this.cognitoIdentifyJS.CognitoUser

    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: userName,
        Pool: this.userPool,
      })

      const callback = (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      }

      user.confirmRegistration(code, false, callback, clientMetadata)
    })
  }

  refreshSession() {
    return this.currentAuthenticatedUser()
  }
}
