import React from 'react'
import { useSelector } from 'react-redux'

import { I18n } from 'utils/i18n'
import { useMeasureNodes } from 'hooks/useMeasureNodes'
import { SelectableBox } from 'components/SelectableBox'
import { ProductOptionHeader } from 'components/ProductOptionHeader'
import { useProductOptionSelect } from 'hooks/useProductOptionSelect'
import { ORDERABLE_STATES } from 'utils/constants'
import { MissingOption } from 'components/MissingOption'

import { webLabels } from './fixtures'
import styles from './ProductOptionsGeneric.css'

export const ProductOptionsGeneric = ({
  config,
  level,
}) => {
  const handleSelect = useProductOptionSelect()
  const optionRefs = React.useRef([])
  const missingLevels = useSelector(state => state.details.missingLevels)
  const levelOptions = useSelector(state => state.details.levelOptions)
  const allSelectedSlugs = useSelector(state => state.details.selectedSlugs)
  const selectedSlug = allSelectedSlugs[level]
  const options = levelOptions[level]
  const selectedOption = options.find(option => option.slug === selectedSlug)
  const minWidth = useMeasureNodes(optionRefs.current, [options.reduce((acc, option) => acc + option[level].name, ``)])
  const isLevelMissing = missingLevels.includes(level)

  if (options) {
    return (
      <div
        className={styles.container}
        id={`product-options-${level}`}
      >
        {!config?.hideHeader && (
          <ProductOptionHeader value={`${I18n.t(webLabels.level.replace(`*`, level.toUpperCase()))}:`}>
            {selectedOption && selectedOption[level].name}
          </ProductOptionHeader>
        )}
        {shouldRender() &&
          <div className={styles.flexGrid}>
            {options.map((option, i) => {
              const {
                availability,
                slug,
              } = option

              return (
                <SelectableBox
                  data-testid={`${level}-${i}`}
                  disabled={!ORDERABLE_STATES.includes(availability)}
                  key={option[level].name}
                  label={option[level].name}
                  nowrap={true}
                  onClick={() => handleSelect({ [level]: slug })}
                  ref={el => { optionRefs.current[i] = el }}
                  selected={slug === selectedSlug}
                  style={{
                    minWidth: minWidth > 40 && minWidth,
                  }}
                  type={config?.buttonType}
                />
              )
            })}
          </div>
        }
        {isLevelMissing &&
          <MissingOption level={level} />
        }
      </div>
    )
  }

  return null

  function shouldRender() {
    if (
      (options.length === 1 && config?.hideSingle) ||
      !options.length
    ) {
      return false
    }

    return true
  }
}
