import React from 'react'
import classNames from 'classnames'

import { Image } from 'components/Image'
import { Svg } from 'components/Svg'

import styles from './ControlTrack.css'
import { ProductCustomisationImage } from '../../../design-system/templates/ProductCustomisationImage'

export const ControlTrack = ({
  activeItem,
  controlsObjectFit,
  controlsPosition,
  controlsRatio,
  items,
  onClick,
}) => {
  return (
    <div
      className={classNames(styles.controlTrack, {
        [styles.start]: controlsPosition === `start`,
      })}
    >
      {items.map((item, i) => (
        <button
          aria-label={item.data.content.alt}
          className={classNames(styles.control, {
            [styles.active]: activeItem === i,
          })}
          key={item.data.content.src}
          name={item.data.content.alt}
          onClick={() => (onClick(i))}
        >
          {renderItem(item)}
        </button>
      ))}
    </div>
  )

  function renderItem(item) {
    if ([`videoItemCarousel`, `videoItemStacked`].includes(item.data.component)) {
      return (
        <div className={styles.wrapper}>
          <Image
            interactive
            metadata={controlsRatio}
            objectFit={controlsObjectFit}
            src={item.data.content.poster}
          />
          <div className={styles.iconWrapper}>
            <Svg
              block
              icon="play"
              size="1.5rem"
            />
          </div>
        </div>
      )
    }

    if (item.data.component === `productCustomisationImage`) {
      return <ProductCustomisationImage key="productCustomisationImage"/>
    }

    return (
      <Image
        alt={item.data.content.alt}
        interactive
        key={item.data.content.src}
        metadata={controlsRatio}
        src={item.data.content.src}
      />
    )
  }
}
