import React from 'react'

import { Accordion } from 'factory-components/Accordion'
import { AccountLink } from 'factory-components/AccountLink'
import { BazaarVoiceReviews } from 'factory-components/BazaarVoiceReviews'
import { BazaarVoiceReviewSummary } from 'factory-components/BazaarVoiceReviewSummary'
import { Breadcrumbs } from 'factory-components/Breadcrumbs'
import { ButtonGroup } from 'factory-components/ButtonGroup'
import { Button } from 'factory-components/Button'
import { CategoryCards } from 'factory-components/CategoryCards'
import { ComponentTemplate } from 'factory-components/ComponentTemplate'
import { ContactForm } from 'factory-components/ContactForm'
import { CountrySelectButton } from 'factory-components/CountrySelectButton'
import { CookieReload } from 'factory-components/CookieReload'
import { Divider } from 'factory-components/Divider'
import { EmailSignupExtended } from 'factory-components/EmailSignupExtended'
import { EmailSignupBar } from 'factory-components/EmailSignupBar'
import { EmailSignupSplash } from 'factory-components/EmailSignupSplash'
import { FavoritesLink } from 'factory-components/FavoritesLink'
import { Filters } from 'factory-components/Filters'
import { FiltersDropdown } from 'factory-components/FiltersDropdown'
import { FiltersMobileToggles } from 'factory-components/FiltersMobileToggles'
import { FiltersSelected } from 'factory-components/FiltersSelected'
import { FiltersSidebar } from 'factory-components/FiltersSidebar'
import { SupportNav } from 'factory-components/SupportNav'
import { Group } from 'factory-components/Group'
import { HeaderNavigation } from 'factory-components/HeaderNavigation'
import { Headline } from 'factory-components/Headline'
import { KlaviyoEmbedForm } from 'factory-components/KlaviyoEmbedForm'
import { LanguageSelector } from 'factory-components/LanguageSelector'
import { LanguageToggle } from 'factory-components/LanguageToggle'
import { ListingPaginator } from 'factory-components/ListingPaginator'
import { Logo } from 'factory-components/Logo'
import { MenuButton } from 'factory-components/MenuButton'
import { MiniCart } from 'factory-components/MiniCart'
import { ModalSplashLink } from 'components/ModalSplashLink'
import { PageNav } from 'factory-components/PageNav'
import { PopularSearches } from 'factory-components/PopularSearches'
import { PosterCard } from 'factory-components/PosterCard'
import { ProductListing } from 'factory-components/ProductListing'
import { ProductCustomisation } from 'pdp-components/ProductCustomisation'
import { ProductListingGrid } from 'factory-components/ProductListingGrid'
import { ProductSuggestions } from 'factory-components/ProductSuggestions'
import { HtmlTemplate } from 'factory-components/HtmlTemplate/HtmlTemplate'
import { FactoryProductTray } from 'factory-components/ProductTray'
import { PulloutNavigation } from 'factory-components/PulloutNavigation'
import { QuerySuggestions } from 'factory-components/QuerySuggestions'
import { RecentlyViewedTray } from 'factory-components/RecentlyViewedTray'
import { ResultsSummary } from 'factory-components/ResultsSummary'
import { Script } from 'factory-components/Script'
import { ShippingTableFactory } from 'factory-components/ShippingTableFactory'
import { Stack } from 'factory-components/Stack'
import { SideNavigation } from 'factory-components/SideNavigation'
import { SiteSearch } from 'factory-components/SiteSearch'
import { SiteSearchBarToggle } from 'factory-components/SiteSearchBarToggle'
import { Tabs } from 'factory-components/Tabs'
import { Text } from 'factory-components/Text'
import { TextAndImage } from 'factory-components/TextAndImage'
import { LazyModuleWrapper } from 'components/LazyModuleWrapper'
import { VideoEmbed } from 'factory-components/VideoEmbed'
import { YotpoReviews } from 'factory-components/YotpoReviews'
import { Image } from 'factory-components/Image'
import { ProductSizeGuidesSupportPage } from 'factory-components/ProductSizeGuidesSupportPage'
import { AddressUnableToShipTable } from 'factory-components/AddressUnableToShipTable'
import { ProductImageGrid } from 'pdp-components/ProductImageGrid'
import { StickyProductDetails } from 'ae-components/PDPStickyProductDetails'
import { SectionLabel } from 'ae-components/PDPSectionLabel'
import { ProductAccordion } from 'ae-components/ProductAccordion'
import { ProductSizeType } from 'ae-components/ProductSizeType'
import { ProductShippingAndReturns } from 'ae-components/PDPShippingAndReturns'
import { TopSearches } from 'factory-components/TopSearches'
import { EmptyListingSearch } from 'factory-components/EmptyListingSearch'

const LazyMarkDownContent = React.lazy(() => (
  import(/* webpackChunkName: "MarkdownContent" */ `factory-components/MarkdownContent/MarkdownContent`)
))

export const MarkDownContent = (props) => {
  return (
    <LazyModuleWrapper isPage={false}>
      <LazyMarkDownContent {...props} />
    </LazyModuleWrapper>
  )
}

export function universalComponents() {
  return {
    accordion: Accordion,
    button: Button,
    buttonGroup: ButtonGroup,
    cookieReload: CookieReload,
    divider: Divider,
    group: Group,
    headline: Headline,
    h1: Headline,
    klaviyoEmbedForm: KlaviyoEmbedForm,
    ProductCustomisation: ProductCustomisation,
    productTray: FactoryProductTray,
    stack: Stack,
    htmlTemplate: HtmlTemplate,
    recentlyViewedTray: RecentlyViewedTray,
    componentTemplate: ComponentTemplate,
    tabs: Tabs,
    textAndImage: TextAndImage,
    image: Image,
    text: Text,
    categoryCards: CategoryCards,
    script: Script,
    emailSignupSplash: EmailSignupSplash,
    bazaarVoiceReviews: BazaarVoiceReviews,
    bazaarVoiceReviewSummary: BazaarVoiceReviewSummary,
    yotpoReviews: YotpoReviews,
    videoEmbed: VideoEmbed,
    shippingTableFactory: ShippingTableFactory,
    countrySelectButton: CountrySelectButton,

    // General
    breadcrumbs: Breadcrumbs,
    emailSignup: EmailSignupBar,
    emailSignupExtended: EmailSignupExtended,

    // PLP
    dropdownFilters: FiltersDropdown,
    sidebarFilters: FiltersSidebar,
    filters: Filters,
    listing: ProductListing,
    listingProductGrid: ProductListingGrid,
    mobileFiltersToggles: FiltersMobileToggles,
    results: ResultsSummary,
    paginator: ListingPaginator,
    posterCard: PosterCard,
    selectedFilters: FiltersSelected,
    sideNavigation: SideNavigation,
    topSearches: TopSearches,
    emptyListingSearch: EmptyListingSearch,

    // Support
    contactForm: ContactForm,
    markDownContent: MarkDownContent,
    pageNav: PageNav,
    footerNav: SupportNav,
    supportNav: SupportNav,
    productSizeGuidesSupportPage: ProductSizeGuidesSupportPage,
    addressUnableToShipTable: AddressUnableToShipTable,

    // Header
    accountLink: AccountLink,
    favoritesLink: FavoritesLink,
    languageSelector: LanguageSelector,
    logo: Logo,
    menuButton: MenuButton,
    miniCart: MiniCart,
    navigation: HeaderNavigation,
    headerNavigation: HeaderNavigation,
    siteSearch: SiteSearch,
    siteSearchBarToggle: SiteSearchBarToggle,
    modalSplashLink: ModalSplashLink,

    // Pullout
    pulloutNavigation: PulloutNavigation,
    languageToggle: LanguageToggle,

    // Site Search
    querySuggestions: QuerySuggestions,
    productSuggestions: ProductSuggestions,
    popularSearches: PopularSearches,

    // AE
    stickyProductDetails: StickyProductDetails,
    productImageGrid: ProductImageGrid,
    sectionLabel: SectionLabel,
    productAccordion: ProductAccordion,
    productSizeType: ProductSizeType,
    productShippingAndReturns: ProductShippingAndReturns,
  }
}
