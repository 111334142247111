import React from "react"
import { Events } from 'design-system/services/events'

const REVEAL_LOCATION_MAP = {
  ProductListing: `product_listing`,
  ProductTray: `product_tray`,
  ProductSuggestions: `product_suggestions`,
}

export function useReveal({
  ms = 3000,
  location,
}) {
  const [reveal, setReveal] = React.useState(false)
  const timer = React.useRef()

  React.useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    },
    [reveal]
  )

  return {
    reveal,
    hide,
    show,
    toggle,
  }

  function hide() {
    timer.current = setTimeout(() => {
      setReveal(false)
    }, ms)
  }

  function show() {
    setReveal(true)
    Events.emitRevealDesigner({
      location: REVEAL_LOCATION_MAP[location],
    })
  }

  function toggle() {
    if (!reveal) {
      Events.emitRevealDesigner({
        location: REVEAL_LOCATION_MAP[location],
      })
    }
    setReveal((prev) => !prev)
  }
}

