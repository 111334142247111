import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFilters } from 'hooks/useFilters'
import { getConfigOptions } from 'global-content/config'
import { I18n } from 'utils/i18n'
import { Button } from 'factory-components/Button'
import { wildcardMatch } from 'utils/wildcardMatch'

export const FiltersSortBy = ({
  buttonWrapperClass,
  filtersPadding,
  onClick,
  type,
}) => {
  const { applySort } = useFilters()
  const selectedSort = useSelector(state => state.listing.selectedSort)
  const { pathname } = useLocation()

  return (
    <div>
      {getConfigOptions(`sortOptions`).map(option => {
        const {
          label,
          onlyAppear,
          doesNotAppear,
          value,
        } = option
        const active = value === selectedSort

        if (
          (onlyAppear && !wildcardMatch(pathname, onlyAppear)) ||
          (doesNotAppear && wildcardMatch(pathname, doesNotAppear))
        ) {
          return null
        }

        return (
          <div key={value}>
            <Button
              aria-pressed={active ? `true` : `false`}
              className={classNames({
                // absolute
                active,

                //scoped
                [buttonWrapperClass]: buttonWrapperClass,
              })}
              data={{
                component: `button`,
                layout: { paddingHorizontal: filtersPadding },
                styling: { type },
              }}
              onClick={() => handleClick(value, active)}
            >
              {I18n.t(label)}
            </Button>
          </div>
        )
      })}
    </div>
  )

  function handleClick(optionName, active) {
    if (!active) {
      applySort(optionName)
    }

    onClick?.()
  }
}
