import React from 'react'

import { updateGoogleAnalytics } from 'services/analytics'
import { getComponentOptions } from 'global-content/components'
import { ProductTraySupportLink } from 'components/ProductTraySupportLink'
import { ProductCardCarousel } from 'components/ProductCardCarousel'
import { ImpressionObserver } from 'components/ImpressionObserver'
import { ProductTrayTabs } from 'components/ProductTrayTabs'
import { ProductGrid } from 'components/ProductGrid'
import { Layout } from 'components/Layout'
import { Factory } from 'components/Factory'
import { REVEAL_LOCATION_MAP } from 'utils/constants'

import styles from './ProductTray.css'

export const ProductTray = ({
  carouselConfig,
  footer,
  header,
  productConfig = {},
  supportConfig = {},
  tabsConfig = { tabs: [] },
}) => {
  const { innerLayout } = getComponentOptions(`productTray`)

  return (
    <ImpressionObserver
      onUniqueImpression={handleProductTrayImpressionAnalytics}
    >
      {header && <Factory items={header.items} />}
      <div className={styles.container}>
        {supportConfig.href && (
          <ProductTraySupportLink
            {...supportConfig}
          />
        )}
        {tabsConfig.showTabs &&
          <ProductTrayTabs
            {...tabsConfig}
          />
        }
        <Layout layout={innerLayout}>
          {carouselConfig ? (
            <ProductCardCarousel
              key={tabsConfig.activeTab}
              {...productConfig}
              {...carouselConfig}
              location={REVEAL_LOCATION_MAP.ProductTray}
            />
          ) : (
            <ProductGrid
              {...productConfig}
              location={REVEAL_LOCATION_MAP.ProductTray}
            />
          )}
        </Layout>
      </div>
      {footer && <Factory items={footer.items} />}
    </ImpressionObserver>
  )

  function handleProductTrayImpressionAnalytics() {
    updateGoogleAnalytics(`productTrayImpression`, {
      analyticsTitle: productConfig.analyticsTitle,
    })
  }
}
