import { getPreviewSetting } from 'global-content/preview'
import { SENTRY_READY } from 'utils/constants'
import { getMetaOptions } from 'global-content/config'
import { ERROR_CODES } from 'utils/errorCodes'
import {
  CSS_CHUNK_ERROR_MSG,
  JS_CHUNK_ERROR_MSG,
  NO_CURRENCY_FOUND_ERROR_MSG,
} from 'utils/constants'

const SENTRY_DEVELOPMENT_DSN_KEY = `792591e1b79bc074eb881b45bf06e4ac`
export async function setupLazySentry(key = SENTRY_DEVELOPMENT_DSN_KEY) {
  return new Promise((resolve, reject) => {
    if (
      !window.$PORTHOSTMAP && // NOTE: to load Sentry locally for debugging, comment out !window.$PORTHOSTMAP
      !window.location.hostname.includes(`localised-test`) &&
      !getPreviewSetting(`active`)
    ) {
      const script = document.createElement(`script`)
      script.onload = () => {
        sentryInit()
        resolve()
      }
      script.onerror = () => {
        reject()
      }

      script.crossorigin = `anonoymous`
      script.type = `text/javascript`
      script.src = `https://js.sentry-cdn.com/${key}.min.js`
      document.getElementById(`scripts`).appendChild(script)
    } else {
      reject(`[${ERROR_CODES[10003]}]`)
    }
  })
}

export function sentryInit() {
  window.Sentry.onLoad(function() {
    window.Sentry.init({
      beforeSend,
      environment: getMetaOptions(`environment`) || `development`,
      release: window.$VERSION,
    })

    window.Sentry.configureScope(function(scope) {
      scope.setUser({ id: window.$shoppingSessionId.value })
      scope.setTag(`gitTag`, window.$RELEASE)

      if (getMetaOptions(`clientTag`)) {
        scope.setTag(`clientTag`, getMetaOptions(`clientTag`))
        scope.setTag(`siteTag`, getMetaOptions(`siteTag`))
      }
    })

    document.dispatchEvent(new CustomEvent(SENTRY_READY, {
      detail: null,
    }))
  })
}

// https://github.com/getsentry/sentry-javascript/issues/2380
// https://github.com/getsentry/sentry-javascript/issues/2210#issuecomment-540553675
// eslint-disable-next-line no-unused-vars
function beforeSend(event, hint) {
  // const error = hint.originalException

  if (
    event.extra?.__serialized__?.detail?.reason?.message === `Extension context invalidated.`
  ) {
    return null
  }

  // Note: We do not want to send this native webpack chunk loading error to Sentry
  // because our app will first attempt to refresh the page to fetch the new js/css files
  // with a new hash number. If the retry fails, then Lithos will throw a custom error which
  // will then send an error to Sentry. This usually occurs while a user is browsing the
  // site while a new lithos release is deployed.
  if (
    hint?.originalException?.code === CSS_CHUNK_ERROR_MSG ||
    (
      hint?.originalException?.name === JS_CHUNK_ERROR_MSG &&
      event?.extra?.storageHashKey === undefined
    )
  ) {
    return null
  }

  // Note: We do not want to send HeadlessChrome errors to Sentry as they may be automated
  // tests or third-party scraping tools that makes the app run in unexpected ways.
  // More details in JIRA: https://localised.atlassian.net/browse/LDP-2983
  if (window?.navigator?.userAgent?.includes(`HeadlessChrome`)) {
    return null
  }

  // Note: We do not want to send a specific error "No currency found!" to Sentry
  // because it comes from a unknown puppeteer script seemingly tracking price changes.
  // Error source "pptr://__puppeteer_evaluation_script__"
  // More details in JIRA: https://localised.atlassian.net/browse/LDP-2983
  const errorValue = event?.exception?.values.length ? event?.exception?.values[0]?.value : ``
  const hintValue = hint?.originalException?.message || ``
  if (
    errorValue.trim() === NO_CURRENCY_FOUND_ERROR_MSG ||
    hintValue.trim() === NO_CURRENCY_FOUND_ERROR_MSG
  ) {
    return null
  }


  // NOTE: Uncomment to debug sentry events
  // console.log({ event, hint })

  return event
}

export function forceLoadSentry() {
  if (window.Sentry && window.Sentry.forceLoad) {
    window.Sentry.forceLoad()
  }
}
