import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { Svg } from 'components/Svg'
import { useRemapLeftAndRight } from 'hooks/useRemapLeftAndRight'
import { closePullout } from 'state/actions/pullout'
import { overlayOff } from 'state/actions/overlay'
import { useDispatch } from 'react-redux'
import { I18n } from 'utils/i18n'
import { webLabels } from './fixtures'
import styles from './Pullout.css'

export const Pullout = ({
  children,
  pulloutSide: pulloutSideRaw,
  style,
}) => {
  const pulloutSide = useRemapLeftAndRight(pulloutSideRaw)
  const active = useSelector(state => state.pullout.active)
  const [scopedActive, setScopedActive] = React.useState()
  const dispatch = useDispatch()

  // so the css transition works
  React.useEffect(() => {
    setScopedActive(active)
  }, [active])

  // Add event listener for Escape key to close the pullout
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === `Escape`) {
        handleClose()
      }
    }
    document.addEventListener(`keydown`, handleKeyDown)
    return () => {
      document.removeEventListener(`keydown`, handleKeyDown)
    }
  }, [])

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.out]: scopedActive,
        [styles.start]: pulloutSide === `start`,
        [styles.end]: pulloutSide === `end`,
      })}
    >
      <div className={styles.closeHeader}>
        <button
          aria-label={I18n.t(webLabels.closeMenu)}
          onClick={handleClose}
        >
          <Svg
            block
            icon="close"
            size="30px"
          />
        </button>
      </div>
      <div
        className={classNames(styles.content)}
        style={style}
      >
        {children}
      </div>
    </div>
  )

  function handleClose() {
    dispatch(overlayOff(`pullout`))
    dispatch(closePullout())
  }
}
