import React from "react"
import { SiteSearch } from 'factory-components/SiteSearch'

export const EmptyListingSearch = () => {
  return (
    <SiteSearch
      data={{
        positioning: {
          searchIconInside: `left`,
        },
        styling: {
          inputBorderSize: `1px`,
          inputBorderColor: `var(--greyLight)`,
        },
      }}
    />
  )
}
