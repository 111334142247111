import React from 'react'

import mediaSizes from 'css/media-sizes.json'

import {
  TABLET_MEDIUM_KEY,
  TABLET_SMALL_KEY,
  MOBILE_LARGE_KEY,
} from 'utils/constants'

export const ImageInner = ({
  'data-testid': testId,
  alt = ``,
  className,
  handleImageLoad,
  metadata,
  role,
  src = ``,
  style = {},
}) => {
  const [missingImage, setMissingImage] = React.useState()
  const responsiveImageTypes = [`jpg`, `jpeg`, `png`, `gif`, `webp`]
  const breakpoints = [
    mediaSizes[MOBILE_LARGE_KEY],
    mediaSizes[TABLET_SMALL_KEY],
    mediaSizes[TABLET_MEDIUM_KEY],
  ]

  const imageProps = {
    alt,
    'data-testid': testId,
    height: metadata?.height,
    onError: pictureLoadError,
    onLoad: handleImageLoad,
    src,
    style,
    width: metadata?.width,
    className,
    role,
  }

  if (imageProps.src && isResponsive(imageProps.src) && isLocal(imageProps.src) && !missingImage) {
    return (<Picture />)
  }

  if (!src) {
    return (
      <div />
    )
  }

  return (
    <Image />
  )

  function Image() {
    return (
      <img
        onLoad={handleImageLoad}
        {...imageProps}
      />
    )
  }

  function Picture() {
    return (
      <picture>
        {renderSourceTags(`.webp`)}
        {renderSourceTags()}
        <source
          srcSet={renameWithBreakpoint({ file: src, extension: `.webp` })}
          type="image/webp"
        />
        <img
          onLoad={handleImageLoad}
          {...imageProps}
        />
      </picture>
    )
  }

  function pictureLoadError() {
    setMissingImage(true)
  }

  function renderSourceTags(extension) {
    return breakpoints.map(breakpoint => {
      return (
        <source
          key={breakpoint}
          media={`(max-width: ${breakpoint}px)`}
          srcSet={renameWithBreakpoint({ file: src, breakpoint, extension })}
        />
      )
    })
  }

  function isResponsive(file) {
    if (typeof file === `string`) {
      if (responsiveImageTypes.includes(file?.match(/\.[^.]*$|$/)[0].slice(1))
        || file.startsWith(`https://m.localised`)) {
        return true
      }
    }

    throw new Error(JSON.stringify(src, null, 2))
  }

  // we are rescaling images only from lithos-content
  function isLocal(file) {
    if (/^\/images/.test(file)) {
      return true
    }
  }

  function renameWithBreakpoint({
    file,
    breakpoint,
    extension,
  }) {
    let {
      filePath,
      ext,
    } = getFileParts(file)
    let append = ``

    if (extension) {
      ext = extension
    }

    if (breakpoint) {
      append = `-$optimized$-${breakpoint}`
    }

    return `${filePath.replace(`/images/`, `/imagesOptimized/`)}${append}${ext}`
  }

  function getFileParts(file) {
    if (typeof file === `string`) {
      let [, filePath, ext] = file.match(/(.+?)(\.[^.]*$|$)/)

      return {
        filePath,
        ext,
      }
    }

    throw new Error(JSON.stringify(src, null, 2))
  }
}
