"use strict";
exports.__esModule = true;
exports.EmitEventType = void 0;
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
var EmitEventType;
(function (EmitEventType) {
    EmitEventType["emitWebsiteInitialLoad"] = "emitWebsiteInitialLoad";
    EmitEventType["emitStartedCheckout"] = "emitStartedCheckout";
    EmitEventType["emitCompleteCheckout"] = "emitCompleteCheckout";
    EmitEventType["emitAddToCart"] = "emitAddToCart";
    EmitEventType["emitAddedToCartFailed"] = "emitAddedToCartFailed";
    EmitEventType["emitIncrementCart"] = "emitIncrementCart";
    EmitEventType["emitViewProduct"] = "emitViewProduct";
    EmitEventType["emitViewProductDetails"] = "emitViewProductDetails";
    EmitEventType["emitClickProduct"] = "emitClickProduct";
    EmitEventType["emitClickFilter"] = "emitClickFilter";
    EmitEventType["emitViewFilter"] = "emitViewFilter";
    EmitEventType["emitIdentifyUserSession"] = "emitIdentifyUserSession";
    EmitEventType["emitResetUserSession"] = "emitResetUserSession";
    EmitEventType["emitRevealDesigner"] = "emitRevealDesigner";
    EmitEventType["emitLanguageSwitched"] = "emitLanguageSwitched";
    EmitEventType["emitErrorPage"] = "emitErrorPage";
    EmitEventType["emitSoft404Page"] = "emitSoft404Page";
    EmitEventType["emitUserSignIn"] = "emitUserSignIn";
    EmitEventType["emitUserSignInFailed"] = "emitUserSignInFailed";
    EmitEventType["emitUserSignInFromCookies"] = "emitUserSignInFromCookies";
    EmitEventType["emitUserSignOut"] = "emitUserSignOut";
    EmitEventType["emitUserCreateAccountStarted"] = "emitUserCreateAccountStarted";
    EmitEventType["emitUserCreateAccountFailed"] = "emitUserCreateAccountFailed";
    EmitEventType["emitUserCreateAccountCompleted"] = "emitUserCreateAccountCompleted";
    EmitEventType["emitPaymentTypeSelected"] = "emitPaymentTypeSelected";
    EmitEventType["emitCheckoutStepViewedBasket"] = "emitCheckoutStepViewedBasket";
    EmitEventType["emitCheckoutStepViewedShipping"] = "emitCheckoutStepViewedShipping";
    EmitEventType["emitCheckoutStepViewedPayment"] = "emitCheckoutStepViewedPayment";
    EmitEventType["emitCheckoutStepViewedReview"] = "emitCheckoutStepViewedReview";
    EmitEventType["emitForgotPasswordStarted"] = "emitForgotPasswordStarted";
    EmitEventType["emitForgotPasswordSent"] = "emitForgotPasswordSent";
    EmitEventType["emitForgotPasswordFailed"] = "emitForgotPasswordFailed";
    EmitEventType["emitForgotPasswordChangeCompleted"] = "emitForgotPasswordChangeCompleted";
    EmitEventType["emitForgotPasswordChangeFailed"] = "emitForgotPasswordChangeFailed";
})(EmitEventType = exports.EmitEventType || (exports.EmitEventType = {}));
/* eslint-enable no-unused-vars */
