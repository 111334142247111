import { useSelector } from 'react-redux'
import { getConfigOptions } from 'global-content/config'

export function useProductMedia({
  activeItem,
  stacked,
  zoomable,
  isCustomised,
  isActive,
  magnifiedImageColor,
} = {}) {
  const productId = useSelector(state => state.details.product.id)
  const levelOptions = useSelector(state => state.details.levelOptions)
  const selectedSlugs = useSelector(state => state.details.selectedSlugs)
  const brand = useSelector(state => state.details.product.brand)
  const name = useSelector(state => state.details.product.name)
  const alt = `${brand.name} ${name}`
  const {
    imageRatio,
    image = {},
    objectFit,
  } = getConfigOptions(`variant`)
  const { borderRadius } = image
  const colorOptions = levelOptions.color
  const selectedColor = colorOptions
    .find(color =>
      color.productSlug ?
        color.productSlug === `${productId}.${selectedSlugs.color}` :
        color.slug === selectedSlugs.color
    )
  const {
    large,
    video,
  } = selectedColor.media


  const images = large.map(
    (img, index) => {
      if (isCustomised && index === 0) {
        return ({
          id: img,
          data: {
            component: `productCustomisationImage`,
            content: {
              alt,
            },
          },
        })
      }
      return {
        id: img,
        data: {
          component: zoomable ? `magnifiedImage` : `productImage`,
          content: {
            src: img,
            alt,
          },
          'data-testid': `product-media`,
          positioning: {
            borderRadius,
            imageRatio,
            objectFit,
          },
          styling: {
            backgroundColor: magnifiedImageColor,
          },
        },
      }
    }
  )

  const videos = video.map((v, i) => ({
    id: v,
    active: isActive || (images.length + i === activeItem),
    'data-testid': `product-media`,
    data: {
      component: stacked ? `videoItemStacked` : `videoItemCarousel`,
      content: {
        poster: large[0],
        src: v,
        alt,
      },
      positioning: {
        imageRatio,
        objectFit: `contain`,
      },
    },
  }))

  return [
    ...images,
    ...videos,
  ]
}
