import {
  ACCOUNT_SET_VALUES,
  ACCOUNT_SIGN_OUT,
  ADD_SUGESTED_USERNAME,
} from 'state/actions'
import { loadCartContents, restartSession } from 'state/actions/cart'
import { createSession } from 'services/api/session'
import {
  accountSignIn,
  accountSignOut,
  accountGetCurrentUser,
} from 'services/api/account'
import { getMetaOptions } from 'global-content/config'

export const setAccountValues = payload => {
  return {
    type: ACCOUNT_SET_VALUES,
    payload,
  }
}

export const signIn = ({
  captcha,
  onFailure,
  onSuccess,
  onFinished,
  userName,
  password,
}) => async(dispatch) => {
  console.log(`Try sign in`)
  try {
    console.log(`Signing out first`)
    await accountSignOut()
    console.log(`Successfully signed out`)
    const data = await accountSignIn({ userName, password, captcha })
    const normalized = signInValues(data, userName)

    dispatch(setAccountValues(normalized))

    // what if this next call fails
    console.log(`Try create session`)
    await createSession(normalized.jwtToken)
    console.log(`Try load cart contents`)
    await dispatch(loadCartContents())
    console.log(`Call onSuccess`)
    const response = onSuccess && onSuccess(normalized)
    onFinished && onFinished(response || undefined)
  } catch (err) {
    console.log(`Call onFailure`)
    const response = onFailure && onFailure(err, userName)
    onFinished && onFinished(response || undefined)
  }
}

export const signOut = () => async(dispatch) => {
  accountSignOut()
  dispatch({
    type: ACCOUNT_SIGN_OUT,
  })

  await dispatch(restartSession())
  await dispatch(loadCartContents())
}

export const addSuggestedUsername = suggestedUsername => {
  return {
    type: ADD_SUGESTED_USERNAME,
    payload: suggestedUsername,
  }
}

export const userSetUp = () => async(dispatch) => {
  if (getMetaOptions(`integrations.cognito.enabled`)) {
    try {
      const data = await accountGetCurrentUser()

      if (data) {
        dispatch(setAccountValues(signInValues(data)))
      }
    } catch (err) {
      return Promise.resolve()
    }
  }

  return Promise.resolve()
}

function signInValues(data, userName) {
  /* eslint-disable camelcase */

  const {
    email,
    family_name,
    given_name,
    phone_number,
    phone_number_verified,
    sub,
  } = data.idToken.payload

  return {
    isUserSignedIn: true,
    suggestedUsername: undefined,
    userEmail: email,
    userFamilyName: family_name,
    userGivenName: given_name,
    userId: sub,
    userJwt: data.accessToken.jwtToken,
    userName: userName,
    userPhoneNumber: phone_number,
    userPhoneNumberVerified: phone_number_verified,
  }
}
