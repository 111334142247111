import React, { Fragment } from 'react'
import classNames from 'classnames'
import get from 'lodash/get'
import { useDetailsPreload } from './useDetailsPreload'
import { getConfigOptions } from 'global-content/config'
import { I18n } from 'utils/i18n'
import { cartItemToDetailsItem } from 'utils/cartItemToDetailsItem'
import { getComponentOptions } from 'global-content/components'
import { CardImage } from 'components/CardImage'
import { Price } from 'components/Price'
import { Link } from 'components/Link'
import { shouldRemoveHashFromSku } from 'utils/shouldRemoveHashFromSku'
import { PreviewImageModal } from '../../design-system/components/PreviewImageModal'
import {
  AvailabilityMessaging as SkuAvailabilityMessaging,
  availabilityMessagingType,
} from 'components/AvailabilityMessaging'

import { webLabels } from './fixtures'
import styles from './useSkuComponents.css'

export function useSkuComponents({
  item,
  testId,
  index,
}) {
  const {
    discounts,
    options: {
      color,
      size,
      sizeType,
    },
    name,
    price,
    quantity,
    sku,
    thumbnailUrl,
    url,
    availability,
    shipDateStart,
    shipDateEnd,
    sale,
    customisation,
  } = item

  const {
    priceField,
    ...details
  } = getFieldsToShow()
  const {
    hideDiscountPercentage,
  } = getComponentOptions(`productCardOptions`)
  const completeUrl = url.includes(`?`) ? url : `${url}?sku=${sku}`
  const onLinkClick = useDetailsPreload()

  return {
    Image: () => (
      <SkuComponentImage
        alt={name}
        completeUrl={completeUrl}
        customisation={customisation}
        index={index}
        onClick={handleClick}
        testId={testId}
        thumbnailUrl={thumbnailUrl}
        title={name}
      />
    ),
    Title: () => (
      <SkuComponentTitle
        completeUrl={completeUrl}
        name={name}
        onClick={handleClick}
      />
    ),
    Sku: () => (
      <SkuComponentSku
        sku={sku}
      />
    ),
    Price: ({ showLabel }) => (
      <SkuComponentPrice
        hideDiscountPercentage={hideDiscountPercentage}
        price={price}
        priceField={priceField}
        sale={sale}
        showLabel={showLabel}
      />
    ),
    Quantity: () => (
      <SkuComponentGenericField
        details={details}
        field="quantity"
      />
    ),
    Color: () => (
      <SkuComponentGenericField
        details={details}
        field="color"
      />
    ),
    Customisation: () => (
      <SkuComponentCustomisedFields
        customisation={customisation}
        src={thumbnailUrl}
      />
    ),
    SizeType: () => (
      <SkuComponentGenericField
        details={details}
        field="sizeType"
      />
    ),
    LocalSize: () => (
      <SkuComponentGenericField
        details={details}
        field="localSize"
      />
    ),
    Size: () => (
      <SkuComponentGenericField
        details={details}
        field={details.localSize ? `localSize` : `size`}
      />
    ),
    Discounts: () => (
      <SkuComponentDiscounts discounts={discounts} />
    ),
    AvailabilityMessaging: () => (
      <SkuAvailabilityMessaging
        availability={availability}
        shipDateEnd={shipDateEnd}
        shipDateStart={shipDateStart}
        type={availabilityMessagingType.existingOrder}
      />
    ),
  }

  function handleClick() {
    onLinkClick({
      item: cartItemToDetailsItem(item),
      color: item.options.color.slug,
    })
  }

  function getFieldsToShow() {
    const fields = {
      color: color.name,
      priceField: price,
      quantity,
    }

    if (sizeType) {
      fields.sizeType = sizeType.name
    }

    // isLocalised
    if (size.labelSize && size.labelSize !== size.name) {
      fields.localSize = size.labelSize
    } else {
      fields.size = size.name
    }

    return fields
  }
}

const SkuComponentImage = ({
  completeUrl,
  customisation,
  index,
  onClick,
  testId,
  thumbnailUrl,
  title,
}) => {
  const mainImage = get(customisation, `display.previewImage.thumbnail`) || thumbnailUrl
  return (
    <CardImage
      data-testid={`${testId}-item-details-image-link-${index}`}
      imageSource={{ mainImage }}
      interactive
      link={completeUrl}
      onClick={onClick}
      title={title}
    />
  )
}

const SkuComponentTitle = ({
  completeUrl,
  name,
  onClick,
}) => {
  return (
    <Link
      aria-label={name}
      name={name}
      onClick={onClick}
      to={completeUrl}
    >
      {name}
    </Link>
  )
}

const SkuComponentSku = ({
  sku,
}) => {
  const showSku = getConfigOptions(`variant.show.sku`)

  // Remove customisation hash from end of the sku for LB client
  if (shouldRemoveHashFromSku()) {
    let parts = sku.split(`_`)
    parts.pop()
    return parts.join(`_`)
  }

  if (showSku) {
    return (
      <div className={styles.detail}>
        <span className={styles.label}>
          {I18n.t(webLabels.sku)}
        </span>
        <span className={styles.value}>
          {sku}
        </span>
      </div>
    )
  }

  return null
}

const SkuComponentPrice = ({
  priceField,
  price,
  showLabel = true,
  hideDiscountPercentage,
}) => {
  if (priceField) {
    return (
      <div className={classNames(styles.detail, styles.price)}>
        {showLabel &&
          <span className={styles.label}>
            {`${I18n.t(webLabels.price)}:`}
          </span>
        }
        <Price
          fontSize="xs"
          hideDiscountPercentage={hideDiscountPercentage}
          price={price}
          showPricingStrategy={false}
        />
      </div>
    )
  }

  return null
}

const SkuComponentGenericField = ({
  details,
  field,
}) => {
  if (details[field]) {
    return (
      <div
        className={styles.detail}
        key={field}
      >
        <span className={styles.label}>
          {`${I18n.t(webLabels[field])}:`}
        </span>
        <span className={styles.value}>
          {details[field]}
        </span>
      </div>
    )
  }

  return null
}

const SkuComponentCustomisedFields = ({ customisation }) => {
  const { imageRatio } = getConfigOptions(`variant`)
  return (
    <>
      {get(customisation, `display.inputs`, []).map(({
        key,
        value,
        keyLabel,
        valueLabel,
      }) => {
        return (
          <div
            className={styles.detail}
            key={key}
          >
            <span className={styles.label}>
              {keyLabel || key}
            </span>
            <span className={styles.value}>
              {valueLabel || value}
            </span>
          </div>
        )
      })}
      {get(customisation, `display.previewImage.full`) && (
        <div
          className={styles.detail}
        >
          <PreviewImageModal
            alt="Customisation"
            button={{
              data: {},
            }}
            html={(
              <Fragment>
                <span className={styles.label}>
                  {I18n.t(`WEB.PRODUCT.PREVIEW`)}
                </span>
                <span className={styles.value}>
                  {I18n.t(`WEB.PRODUCT.PREVIEW-CLICK`)}
                </span>
              </Fragment>
            )}
            imageRatio={imageRatio}
            loading={false}
            src={customisation.display.previewImage.full}
          />
        </div>
      )}
    </>
  )
}

const SkuComponentDiscounts = ({ discounts }) => {
  if (discounts) {
    return (
      <div className={styles.discounts}>
        {discounts.map(discount => {
          const label = I18n.t(discount.messageId, {
            returnKey: false, // might want to set this to true if web labels are not being used
          })

          if (label) {
            return (
              <div
                className={styles.discount}
                key={discount.name}
              >
                {label}
              </div>
            )
          }

          return null
        })}
      </div>
    )
  }

  return null
}
