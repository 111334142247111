import { constructUrl } from 'models/utils'
import { getMetaOptions } from 'global-content/config'
import get from 'lodash/get'
import pick from 'lodash/pick'

export function normalizeCartResponse(data) {
  const cartSummary = normalizeCartSummary(data.cartSummary)
  const normalized = {
    ...data,
    items: data.items ? data.items.map(item => normalizeCartItem(item)) : [],
    cartSummary,
  }

  return normalized
}

function normalizeCartSummary(cartSummary) {
  const {
    totalDiscountShipping,
    deliveryCost,
    deliveryNetCost,
  } = cartSummary
  const calculateTaxAtCheckout = getMetaOptions(`calculateTaxAtCheckout`)
  const shippingPrice = calculateTaxAtCheckout ? deliveryNetCost : deliveryCost

  const shippingCost = shippingPrice - totalDiscountShipping
  const isDiscounted = shippingCost !== shippingPrice
  const isFree = shippingCost === 0
  return {
    ...cartSummary,
    isDiscounted,
    isFree,
    shippingCost,
    shippingPrice,
  }
}

function normalizeCartItem(item) {
  const {
    productId,
    slug,
    thumbnailUrl: thumbnail,
    customisation: cartCustomisation,
  } = item

  const thumbnailUrl = get(item, `customisation.display.previewImage.thumbnail`, thumbnail)
  const customisation = normailzeCustomization(cartCustomisation)

  return {
    ...item,
    productId,
    slug,
    thumbnailUrl,
    customisation,
    url: constructUrl({ productId, slug }),
  }
}

function normailzeCustomization(customisation) {
  if (!customisation) {
    return undefined
  }
  return {
    ...customisation,
    inputs: get(customisation, `inputs`, []).map(input => pick(input, [ `key`, `value` ])),
  }
}
