import React from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { Heading } from 'components/Heading'
import { SideNavLink } from 'components/SideNavLink'
import { Svg } from 'components/Svg'

import styles from './LinkGroups.css'

export const LinkGroups = ({
  activeSection,
  groups,
}) => {
  const [showMobileNav, setShowMobileNav] = React.useState(false)
  const { pathname } = useLocation()

  const activeGroup = getActiveGroup()

  return (
    <div className={styles.container}>
      <div className={styles.desktopHeader}>
        <Heading
          content={{
            html: activeGroup.label,
          }}
          styling={{
            fontFamily: `primary`,
            fontSize: `small`,
            universalColor: true,
          }}
        />
      </div>
      <button
        className={styles.menuToggle}
        onClick={() => setShowMobileNav(!showMobileNav)}
      >
        {activeGroup.label}
        <Svg
          icon={showMobileNav ? `minus` : `plus`}
          size="2rem"
        />
      </button>
      <ul
        className={classNames(styles.pageNav, {
          [styles.open]: showMobileNav,
        })}
      >
        {activeGroup.items && activeGroup.items.map(({
          className,
          label,
          onClick,
          path,
          subItems,
          id,
          isCustomLink,
          isPrivacyPolicy,
        }) => (
          <li
            className={styles.item}
            key={label}
          >
            <SideNavLink
              active={checkForActive(path, subItems)}
              button={isCustomLink}
              className={className}
              id={id}
              isPrivacyPolicy={isPrivacyPolicy}
              isValid={true}
              onClick={onClick}
              to={path}
              value={label}
            />
            {subItems &&
              <ul className={styles.subNav}>
                {subItems.map(subitem => {
                  return (
                    <li key={subitem.label}>
                      <SideNavLink
                        active={subitem.path === pathname}
                        button={subitem.isCustomLink}
                        className={subitem.class}
                        id={subitem.id}
                        isPrivacyPolicy={subitem.isPrivacyPolicy}
                        isValid={true}
                        onClick={subitem.onClick}
                        to={subitem.path}
                        value={subitem.label}
                      />
                    </li>
                  )
                })}
              </ul>
            }
          </li>
        ))}
      </ul>
    </div>
  )

  function getActiveGroup() {
    const slug = activeSection || pathname.split(`/`)[1]

    return groups.find(item => item.slug === slug) || {}
  }

  function checkForActive(path, subItems) {
    if (subItems) {
      return Boolean(subItems.find(item => item.path === pathname))
    }

    return path === pathname
  }
}
