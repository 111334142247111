import merge from 'lodash/merge'

import store from 'state/reducers'
import { getCampaigns } from 'state/actions/campaigns'
import { I18n } from 'utils/i18n'
import { updateLangDir } from 'state/actions/site'
import { loadContent } from 'services/content'
import { getSourceFile } from 'utils/getSourceFile'
import { generateNavTreePaths } from 'utils/generateNavTreePaths'
import { setContent } from 'global-content/content'
import { extractSEORoutesFromNavTree } from 'utils/extractSEORoutesFromNavTree'

export function contentDependencies(language) {
  return Promise.all([
    I18n.get(language),
    loadClpRoutes(),
    loadSeoData(language),
    loadPromotions(language),
    loadHead(language),
    loadHeader(language),
    loadNavigation(language),
    store.dispatch(getCampaigns(language)),
    store.dispatch(updateLangDir(language)),
  ])
}
function loadClpRoutes() {
  // returns list of CLP routes from a json file generated during build process
  // in lithos-content. these routes are then injected into our router
  return loadContent(`/data/clp-routes.json`).then(data => {
    window.$content.routes = {
      list: data.content,
    }
  })
}
function loadSeoData(language) {
  return Promise.all([
    loadContent(getSourceFile(`/data/navTree`, language)),
    loadContent(getSourceFile(`/data/seoData`, language)),
  ]).then(([tree, seoData]) => {
    const navTreeSEO = extractSEORoutesFromNavTree(tree.content)
    const pages = Object.assign({}, seoData.content.pages, navTreeSEO)

    seoData.content.pages = pages

    setContent({
      target: `seoData`,
      content: seoData.content,
      language,
    })
  })
}

function loadPromotions(language) {
  return loadContent(getSourceFile(`/data/promotions`, language)).then(data => {
    setContent({
      target: `promotions`,
      content: data.content,
      language,
    })
  })
}

function loadHead(language) {
  return loadContent(getSourceFile(`/data/head`, language)).then(data => {
    setContent({
      target: `head`,
      content: data.content,
      language,
    })
  })
}

function loadHeader(language) {
  return loadContent(getSourceFile(`/data/header`, language)).then(data => {
    setContent({
      target: `header`,
      content: data.content,
      language,
    })
  }).catch(() => {
    Promise.resolve()
  })
}
function loadNavigation(language) {
  return Promise.all([
    loadContent(getSourceFile(`/data/navTree`, language)),
    loadContent(getSourceFile(`/data/navMenu`, language)),
  ]).then(([tree, navMenu]) => {
    const navTree = generateNavTreePaths(tree.content)

    setContent({
      target: `navTree`,
      content: navTree,
      language,
    })

    setContent({
      target: `navMenu`,
      content: mergedNavs({ navTree, navMenu }),
      language,
    })
  })
}

function mergedNavs({
  navTree,
  navMenu,
}) {
  let build = {
    mobileNavigation: { // change to pulloutNavigation
      navTree,
    },
  }
  const { content } = navMenu

  Object.keys(content).forEach(menuArea => {
    build[menuArea] = {
      ...content[menuArea],
      navTree: content[menuArea].replacement || merge({}, navTree, content[menuArea].augmentations),
    }
  })

  return build
}
