import React from 'react'
import classNames from 'classnames'

import { getComponentOptions } from 'global-content/components'
import { I18n } from 'utils/i18n'
import { Svg } from 'components/Svg'
import { useMediaQuery } from 'react-responsive'
import { getConfigOptions } from 'global-content/config'
import { webLabels } from './fixtures'
import styles from './ScrollUpButton.css'
import mediaSizes from 'css/media-sizes.json'

export const ScrollUpButton = () => {
  const { size } = getComponentOptions(`scrollUp`)
  const [visible, setVisible] = React.useState(false)
  const scrollListener = React.useCallback(() => checkScrollPosition(), [visible])
  const isMobile = useMediaQuery({
    minWidth: 0,
    maxWidth: mediaSizes[`tabletMedium`],
  })
  const enableSticky = getConfigOptions(`components.addToCart.enableSticky`)

  React.useEffect(() => {
    window.addEventListener(`scroll`, scrollListener)

    return () => {
      window.removeEventListener(`scroll`, scrollListener)
    }
  }, [scrollListener])

  const name = I18n.t(webLabels.scrollUp)

  return (!isMobile || !enableSticky) && (
    <button
      aria-label={name}
      className={classNames(styles.container, {
        [styles.visible]: visible,
      })}
      name={name}
      onClick={scrollToTop}
    >
      <Svg
        icon="arrow"
        size={size}
      />
    </button>
  )

  function checkScrollPosition() {
    const {
      scrollY,
      innerHeight,
    } = window

    const toShow = scrollY > innerHeight && !visible
    const toHide = scrollY < innerHeight && visible

    if (toShow) {
      setVisible(true)
    } else if (toHide) {
      setVisible(false)
    }
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: `smooth`,
    })
  }
}
