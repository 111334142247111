"use strict";
exports.__esModule = true;
exports.useEmitAddToCartEvent = void 0;
var events_1 = require("../../../services/events");
var storage_1 = require("../../../services/storage");
var useEmitAddToCartEvent = function () {
    var emitAddToCartEvent = function (_a) {
        var associatedPlp = _a.associatedPlp, buttonAnchorPosition = _a.buttonAnchorPosition, pageArea = _a.pageArea, buttonType = _a.buttonType, product = _a.product, skuOptions = _a.skuOptions, productQuantity = _a.productQuantity;
        storage_1.Storage.addPlpAssociation({
            associatedPlp: associatedPlp,
            productId: product.id
        });
        var plpAssociations = storage_1.Storage.getPlpAssociations();
        events_1.Events.emitAddToCart({
            associatedPlp: plpAssociations[product.id],
            buttonAnchorPosition: buttonAnchorPosition,
            buttonType: buttonType,
            pageArea: pageArea,
            product: product,
            productQuantity: productQuantity,
            skuOptions: skuOptions
        });
    };
    var emitIncrementCartEvent = function (_a) {
        var associatedPlp = _a.associatedPlp, buttonAnchorPosition = _a.buttonAnchorPosition, pageArea = _a.pageArea, buttonType = _a.buttonType, cartItem = _a.cartItem;
        var plpAssociations = storage_1.Storage.getPlpAssociations();
        events_1.Events.emitIncrementCart({
            associatedPlp: plpAssociations[cartItem.productId] || associatedPlp,
            buttonAnchorPosition: buttonAnchorPosition,
            buttonType: buttonType,
            cartItem: cartItem,
            pageArea: pageArea
        });
    };
    return {
        emitAddToCartEvent: emitAddToCartEvent,
        emitIncrementCartEvent: emitIncrementCartEvent
    };
};
exports.useEmitAddToCartEvent = useEmitAddToCartEvent;
