export const preloadImage = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image()
    img.src = url
    img.onload = function() {
      resolve(img)
    }
    img.onerror = function() {
      reject(new Error(`Error loading image: ` + url))
    }
  })
}