import React from 'react'

import { getConfigOptions } from 'global-content/config'
import { Image } from 'components/Image'
import { Link } from 'components/Link'

import styles from './CardImage.css'

export const CardImage = ({
  'data-testid': testId,
  imageBackgroundColor,
  imagePadding,
  imageSource,
  interactive = false,
  link,
  onClick,
  overlay = `light`,
  title,
  role,
}) => {
  const {
    imageRatio,
    image = {},
    objectFit,
  } = getConfigOptions(`variant`)

  return (
    <Link
      aria-label={title}
      className={styles.imageWrapper}
      data-testid={`${testId}-image-link`}
      name={title}
      onClick={onClick}
      title={title}
      to={link}
    >
      <Image
        alt={title}
        backgroundColor={imageBackgroundColor}
        borderRadius={image.borderRadius}
        hoverSrc={imageSource.secondaryImage}
        interactive={interactive}
        metadata={imageRatio}
        objectFit={objectFit}
        overlay={overlay}
        padding={imagePadding}
        role={role}
        src={imageSource.mainImage}
      />
    </Link>
  )
}
