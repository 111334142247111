"use strict";
exports.__esModule = true;
exports.maskEmailWithAsterisks = exports.maskValueToAsterisk = void 0;
/**
 * Function will keep the first few original characters but mask the rest as
 * asterisks (*). It is used to redact personal information.
 */
function maskValueToAsterisk(inputString) {
    var NUM_OG_CHARACTERS = 3;
    if (inputString.length <= NUM_OG_CHARACTERS) {
        return inputString;
    }
    // Leave the first few characters unmasked, but mask the rest of the string.
    var unmakedPartition = inputString.slice(0, NUM_OG_CHARACTERS);
    // regex pattern is to replace all except for white space with asterisk
    var maskedPartition = inputString.slice(NUM_OG_CHARACTERS).replace(/[^ ]/g, "*");
    return unmakedPartition.concat(maskedPartition);
}
exports.maskValueToAsterisk = maskValueToAsterisk;
/**
 * Function will mask email address but keep the @ symbol.
 */
function maskEmailWithAsterisks(input) {
    if ((Boolean(input) === false) || (input.length === 0)) {
        return "";
    }
    // regex pattern is to replace all except for @ symbol with asterisk (*)
    var maskedExceptAtSymbol = input.replace(/[^@]/g, "*");
    return maskedExceptAtSymbol;
}
exports.maskEmailWithAsterisks = maskEmailWithAsterisks;
