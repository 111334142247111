export const ACCOUNT_SET_VALUES = `ACCOUNT_SET_VALUES`
export const ACCOUNT_SIGN_OUT = `ACCOUNT_SIGN_OUT`
export const ADD_ACCOUNT_PAYMENT_METHOD = `ADD_ACCOUNT_PAYMENT_METHOD`
export const ADD_ADDRESS = `ADD_ADDRESS`
export const ADD_CART_ITEM = `ADD_CART_ITEM`
export const ADD_COMPONENTS = `ADD_COMPONENTS`
export const ADD_CONTENT = `ADD_CONTENT`
export const ADD_SUGESTED_USERNAME = `ADD_SUGESTED_USERNAME`
export const ADD_MESSAGE = `ADD_MESSAGE`
export const ADMIN_MODE = `ADMIN_MODE`
export const AJV_AVAILABLE = `AJV_AVAILABLE`
export const APP_IS_READY = `APP_IS_READY`
export const APPLY_VOUCHER = `APPLY_VOUCHER`
export const CANCEL_ACTION = `CANCEL_ACTION`
export const CAPTCHA_AVAILABLE = `CAPTCHA_AVAILABLE`
export const CHANGE_LANGUAGE = `CHANGE_LANGUAGE`
export const CLEAR_MESSAGES = `CLEAR_MESSAGES`
export const CLEAR_REDIRECT = `CLEAR_REDIRECT`
export const CLOSE_PULLOUT = `CLOSE_PULLOUT`
export const DELETE_ACCOUNT_PAYMENT_METHOD = `DELETE_ACCOUNT_PAYMENT_METHOD`
export const DELETE_ADDRESS = `DELETE_ADDRESS`
export const DETAILS_ADD_MESSAGE = `DETAILS_ADD_MESSAGE`
export const DETAILS_PRELOAD = `DETAILS_PRELOAD`
export const DETAILS_REMOVE_MESSAGE = `DETAILS_REMOVE_MESSAGE`
export const DETECTED_COUNTRY = `DETECTED_COUNTRY`
export const DISABLE_CHECKOUT_SUBMIT = `DISABLE_CHECKOUT_SUBMIT`
export const EMAIL_VALIDATION_AVAILABLE = `EMAIL_VALIDATION_AVAILABLE`
export const ENABLE_CHECKOUT_SUBMIT = `ENABLE_CHECKOUT_SUBMIT`
export const ENABLE_CUSTOMISATION = `ENABLE_CUSTOMISATION`
export const ENABLE_TEST_PAYMENTS = `ENABLE_TEST_PAYMENTS`
export const FAVORITE_ADD = `FAVORITE_ADD`
export const FAVORITE_REMOVE = `FAVORITE_REMOVE`
export const FINGERPRINT_DEVICE = `FINGERPRINT_DEVICE`
export const FORM_VALIDATION_AVAILABLE = `FORM_VALIDATION_AVAILABLE`
export const GET_CAMPAIGNS = `GET_CAMPAIGNS`
export const GET_PRODUCT = `GET_PRODUCT`
export const LIST_ACCOUNT_PAYMENT_METHODS = `LIST_ACCOUNT_PAYMENT_METHODS`
export const LIST_ALL_ADDRESSES = `LIST_ALL_ADDRESSES`
export const LIST_BILLING_ADDRESSES = `LIST_BILLING_ADDRESSES`
export const LIST_SHIPPING_ADDRESSES = `LIST_SHIPPING_ADDRESSES`
export const LOAD_ADDRESS_SCHEMA = `LOAD_ADDRESS_SCHEMA`
export const LOAD_BRANDS = `LOAD_BRANDS`
export const LOAD_CART_CONTENTS = `LOAD_CART_CONTENTS`
export const LOAD_FULL_FILTER_SET = `LOAD_FULL_FILTER_SET`
export const LOAD_PAGE_FILTERS = `LOAD_PAGE_FILTERS`
export const LOAD_PRODUCTS = `LOAD_PRODUCTS`
export const LOAD_SCHEMA = `LOAD_SCHEMA`
export const LOADED_DEPENDENCIES = `LOADED_DEPENDENCIES`
export const MATCH_BILLING_AND_SHIPPING_ADDRESS = `MATCH_BILLING_AND_SHIPPING_ADDRESS`
export const NAV_USES_OVERLAY = `NAV_USES_OVERLAY`
export const NO_PRODUCT = `NO_PRODUCT`
export const OPEN_PULLOUT = `OPEN_PULLOUT`
export const OVERLAY_OFF = `OVERLAY_OFF`
export const OVERLAY_ON = `OVERLAY_ON`
export const PHONE_LIBRARY_AVAILABLE = `PHONE_LIBRARY_AVAILABLE`
export const PLACE_ORDER = `PLACE_ORDER`
export const PRETOGGLE_LANGUAGE = `PRETOGGLE_LANGUAGE`
export const POST_PRODUCT_CUSTOMIZATION = `POST_PRODUCT_CUSTOMIZATION`
export const RECREATE_CART = `RECREATE_CART`
export const REMOVE_ACCOUNT_PAYMENT_METHOD_ERROR = `REMOVE_ACCOUNT_PAYMENT_METHOD_ERROR`
export const REMOVE_ADDRESS_ERROR = `REMOVE_ADDRESS_ERROR`
export const REMOVE_REJECTION_ERROR = `REMOVE_REJECTION_ERROR`
export const REMOVE_VOUCHER = `REMOVE_VOUCHER`
export const REMOVE_VOUCHER_MESSAGE = `REMOVE_VOUCHER_MESSAGE`
export const RESTART_SESSION = `RESTART_SESSION`
export const ROUTE_CHANGE = `ROUTE_CHANGE`
export const SAVE_SHIPPING_SELECTION = `SAVE_SHIPPING_SELECTION`
export const SEEN_CAPTCHA = `SEEN_CAPTCHA`
export const SEND_CONSUMER_PROPERTIES = `SEND_CONSUMER_PROPERTIES`
export const SET_ACTIVE_TOOLTIP = `SET_ACTIVE_TOOLTIP`
export const SET_CAPTCHA_TOKEN_V3 = `SET_CAPTCHA_TOKEN_V3`
export const SET_DEFAULT_ACCOUNT_PAYMENT_METHOD = `SET_DEFAULT_ACCOUNT_PAYMENT_METHOD`
export const SET_EMAIL_SIGNUP_DISABLED = `SET_EMAIL_SIGNUP_DISABLED`
export const SET_EMAIL_SIGNUP_ERROR = `SET_EMAIL_SIGNUP_ERROR`
export const SET_EMAIL_SIGNUP_SUCCESS = `SET_EMAIL_SIGNUP_SUCCESS`
export const SET_EMAIL_SIGNUP_CONFIRM = `SET_EMAIL_SIGNUP_CONFIRM`
export const SET_FOCUSSED_NAV_ITEM = `SET_FOCUSSED_NAV_ITEM`
export const SET_INCHES = `SET_INCHES`
export const SET_LANG_DIR = `SET_LANG_DIR`
export const SET_PENDING_QUANTITY = `SET_PENDING_QUANTITY`
export const PRE_SELECT_PRODUCT_CUSTOMISATION = `PRE_SELECT_PRODUCT_CUSTOMISATION`
export const SET_TAX_ERROR = `SET_TAX_ERROR`
export const SET_TAX_LOADING = `SET_TAX_LOADING`
export const SET_UNABLE_TO_SHIP_ERROR = `SET_UNABLE_TO_SHIP_ERROR`
export const SHOW_V2_CAPTCHA = `SHOW_V2_CAPTCHA`
export const START_EXTERNAL_PAYMENT_METHOD = `START_EXTERNAL_PAYMENT_METHOD`
export const TERRITORIES_AVAILABLE = `TERRITORIES_AVAILABLE`
export const UPDATE_ADDRESS = `UPDATE_ADDRESS`
export const UPDATE_AGREEMENT = `UPDATE_AGREEMENT`
export const UPDATE_CART_ITEM = `UPDATE_CART_ITEM`
export const UPDATE_CONSUMER_DETAILS = `UPDATE_CONSUMER_DETAILS`
export const UPDATE_ENCOURAGED_FIELDS_ERRORS = `UPDATE_ENCOURAGED_FIELDS_ERRORS`
export const UPDATE_ENCOURAGED_FIELDS_VALUES = `UPDATE_ENCOURAGED_FIELDS_VALUES`
export const UPDATE_FILTER_OPTIONS = `UPDATE_FILTER_OPTIONS`
export const UPDATE_MISSING_LEVELS = `UPDATE_MISSING_LEVELS`
export const UPDATE_NAVIGATION = `UPDATE_NAVIGATION`
export const UPDATE_PAYMENT_METHOD_TYPE = `UPDATE_PAYMENT_METHOD_TYPE`
export const UPDATE_PENDING_FILTER_OPTIONS = `UPDATE_PENDING_FILTER_OPTIONS`
export const UPDATE_SAVE_SHIPPING_ADDRESS = `UPDATE_SAVE_SHIPPING_ADDRESS`
export const UPDATE_SELECTED_ACCOUNT_PAYMENT_METHOD = `UPDATE_SELECTED_ACCOUNT_PAYMENT_METHOD`
export const UPDATE_SELECTED_BILLING_ADDRESS = `UPDATE_SELECTED_BILLING_ADDRESS`
export const UPDATE_SELECTED_OPTION = `UPDATE_SELECTED_OPTION`
export const UPDATE_SELECTED_SHIPPING_ADDRESS = `UPDATE_SELECTED_SHIPPING_ADDRESS`
export const UPDATE_SELECTED_SHIPPING_VALIDATION = `UPDATE_SELECTED_SHIPPING_VALIDATION`
export const UPDATE_SELECTED_THIRD_PARTY_PAYMENT_METHOD = `UPDATE_SELECTED_THIRD_PARTY_PAYMENT_METHOD`
export const UPDATE_STORE_PAYMENT_METHOD = `UPDATE_STORE_PAYMENT_METHOD`
export const USED_TAB = `USED_TAB`
export const USER_AUTH_AVAILABLE = `USER_AUTH_AVAILABLE`
export const VALIDATE_PRODUCT_CUSTOMISATION = `VALIDATE_PRODUCT_CUSTOMISATION`
