import React from 'react'
import classNames from 'classnames'
import { getConfigOptions } from 'global-content/config'
import {
  PROMOTIONAREA_PRODUCTCARD_ADDITIONAL,
  PROMOTIONAREA_PRODUCTCARD_BADGE,
  PROMOTIONAREA_PRODUCTCARD_BANNER,
  AVAILABILITY_FLAGS,
  AVAILABILITY_LOWSTOCK,
  AVAILABILITY_PREORDER,
} from 'utils/constants'
import { useRemapTextAlign } from 'hooks/useRemapTextAlign'
import { FavoriteButton } from 'components/FavoriteButton'
import { Badge } from 'components/Badge'
import { CardImage } from 'components/CardImage'
import { Price } from 'components/Price'
import { Promotions } from 'components/Promotions'
import { Swatches } from 'components/Swatches'
import { BadgePreorder } from 'components/BadgePreorder'
import { ProductCardHeader } from 'components/ProductCardHeader'
import styles from './ProductCard.css'

export const ProductCard = ({
  favoritePage,
  handleClick,
  location,
  onColorChange,
  position,
  product,
  productCardOptions,
  selectedColor,
  selectedImage,
  url,
}) => {
  const {
    descriptionPadding,
    favIconSize,
    imageBackgroundColor,
    imagePadding,
    priceFontSize = `small`,
    priceFontWeight,
    rankingSize,
    showBrand,
    showFavoriteButton,
    showLowStock,
    showPrice,
    showRanking,
    showSwatches,
    showTitle,
    swatchLimit,
    swatchSize,
    hideDiscountPercentage,
    textAlign: textAlignRaw,
    titleWrap,
    swatchMinimum = 0,
  } = productCardOptions

  const {
    brand,
    options,
    name,
    price,
    priceRange,
    promotions = {},
    productAvailability,
    shippingDateRange,
  } = product

  const altTagContent = `${brand.name} ${name}`
  const testId = `product-card-${position}`
  const showBrandName = shouldShowBrandName()
  const textAlign = useRemapTextAlign(textAlignRaw)

  return (
    <div
      data-testid={testId}
      style={{ textAlign }}
    >
      <div className={styles.cardImageWrapper}>
        {promotions[PROMOTIONAREA_PRODUCTCARD_BADGE] &&
          <div className={styles.badgeWrapper}>
            <Promotions
              promotions={promotions[PROMOTIONAREA_PRODUCTCARD_BADGE]}
              type="badge"
            />
          </div>
        }

        {productAvailability === AVAILABILITY_FLAGS[3] && getConfigOptions(`algolia.fetchOos`) &&
          <div className={styles.oosBadge}>
            <Badge type="outOfStock" />
          </div>
        }

        <CardImage
          data-testid={testId}
          imageBackgroundColor={imageBackgroundColor}
          imagePadding={imagePadding}
          imageSource={selectedImage}
          link={url}
          onClick={handleClick}
          position={position}
          {...getImageAttributes()}
        />

        {showRanking &&
          <div className={classNames(styles.ranking, styles[rankingSize])}>
            {position}
          </div>
        }

        {showFavoriteButton &&
          <div className={styles.favoriteWrapper}>
            <FavoriteButton
              removeIcon={favoritePage}
              size={favIconSize}
              variant={product}
            />
          </div>
        }

        {promotions[PROMOTIONAREA_PRODUCTCARD_BANNER] &&
          <div className={styles.bannerWrapper}>
            <Promotions
              promotions={promotions[PROMOTIONAREA_PRODUCTCARD_BANNER]}
              type="banner"
            />
          </div>
        }
      </div>

      {(
        showBrandName ||
        showTitle ||
        showPrice ||
        showSwatches ||
        promotions[PROMOTIONAREA_PRODUCTCARD_ADDITIONAL] ||
        (productAvailability === AVAILABILITY_LOWSTOCK && showLowStock) ||
        (productAvailability === AVAILABILITY_PREORDER)
      ) &&
      <div style={{ padding: descriptionPadding }}>

        <ProductCardHeader
          label={brand.name}
          location={location}
          onClick={handleClick}
          reveal={shouldRevealHeader()}
          showLabel={showBrandName}
          showTitle={showTitle}
          title={name}
          titleWrap={titleWrap}
          url={url}
        />
        {showPrice &&
          <div className={styles.priceWrapper}>
            <Price
              fontSize={priceFontSize}
              fontWeight={priceFontWeight}
              hideDiscountPercentage={hideDiscountPercentage}
              price={price}
              priceRange={priceRange}
              showRange
            />
          </div>
        }

        {showSwatches && swatchMinimum <= options.length &&
          <Swatches
            limit={swatchLimit}
            onSelect={onColorChange}
            options={options}
            selectedColor={selectedColor}
            swatchSize={swatchSize}
          />
        }
        {promotions[PROMOTIONAREA_PRODUCTCARD_ADDITIONAL] &&
          <div className={styles.textWrapper}>
            <Promotions
              promotions={promotions[PROMOTIONAREA_PRODUCTCARD_ADDITIONAL]}
              type="text"
            />
          </div>
        }
        {productAvailability === AVAILABILITY_FLAGS[2] && showLowStock &&
          <Badge type="lowStock" />
        }
        {productAvailability === AVAILABILITY_PREORDER &&
          <div className={styles.textWrapper}>
            <BadgePreorder shippingDateRange={shippingDateRange} />
          </div>
        }
      </div>
      }
    </div>
  )

  function getImageAttributes() {
    if (shouldRevealHeader()) {
      return {
        title: undefined,
        role: `presentation`,
      }
    }

    return {
      title: altTagContent,
    }
  }

  function shouldRevealHeader() {
    return brand.tag === null
  }

  function shouldShowBrandName() {
    return brand.name && showBrand && getConfigOptions(`variant.show.brand`)
  }
}
