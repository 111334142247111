import React, { Fragment } from 'react'
import classNames from 'classnames'
import parse, { domToReact } from 'html-react-parser'

import { useRemapTextAlign } from 'hooks/useRemapTextAlign'
import { sizeFallThrough } from 'utils/sizeFallThrough'
import { Link } from 'components/Link'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { TextError } from 'components/TextError'

import styles from './HtmlText.css'

export const HtmlText = (props) => {
  return (
    <ErrorBoundary
      errorComponent={TextError}
      errorTitle="HtmlText error"
      severity={2}
    >
      <HtmlTextComponent {...props} />
    </ErrorBoundary>
  )
}

const HtmlTextComponent = ({
  className,
  color,
  elements,
  fontFamily,
  fontSize,
  fontWeight,
  html,
  itemProp,
  textAlign: textAlignRaw,
  textBlock,
  noWrapper,
  backgroundColor,
  lineHeight,
  ...props
}) => {
  const textAlign = useRemapTextAlign(textAlignRaw)

  if (noWrapper) {
    return (
      <Fragment>
        {render(html, elements)}
      </Fragment>
    )
  }

  return (
    <div
      className={classNames(styles.htmlText, className, {
        [styles.large]: fontSize === `large`,
        [styles.regular]: fontSize === `regular`,
        [styles.small]: fontSize === `small`,
        [styles.xs]: fontSize === `xs`,
        [styles.primary]: fontFamily === `primary`,
        [styles.secondary]: fontFamily === `secondary`,
        [styles.custom]: fontFamily === `custom`,
        [styles.custom2]: fontFamily === `custom2`,
        [styles.custom3]: fontFamily === `custom3`,
        [styles.custom4]: fontFamily === `custom4`,
        [styles.textBlock]: textBlock,
      })}
      itemProp={itemProp}
      style={{
        color,
        fontSize: sizeFallThrough([`large`, `regular`, `small`, `xs`], fontSize),
        fontWeight,
        textAlign,
        backgroundColor,
        lineHeight,
      }}
      {...props}
    >
      {render(html, elements)}
    </div>
  )
}

function render(html, elements) {
  try {
    return parse(html, {
      replace: node => replace(node, elements),
    })
  } catch (e) {
    throw new Error(JSON.stringify(html, null, 2))
  }
}

function replace(node, childrenStyling) {
  const {
    attribs,
    children,
    name,
  } = node
  let style

  if (childrenStyling && childrenStyling[name]) {
    style = childrenStyling[name]
  }

  if (style) {
    const Tag = name

    return (
      <Tag
        {...attribs}
        style={style}
      >
        {domToReact(children)}
      </Tag>
    )
  }

  if (name === `a`) {
    return (
      <Link
        style={style}
        target={attribs.target}
        to={attribs.href}
      >
        {domToReact(children)}
      </Link>
    )
  }
}
