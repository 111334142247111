import React from 'react'
import classNames from 'classnames'

import { ImpressionObserver } from 'components/ImpressionObserver'
import { AspectRatio } from 'components/AspectRatio'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { ImageInner } from './ImageInner'
import { TextError } from 'components/TextError'

import styles from './Image.css'

export const Image = ({
  alt = ``,
  backgroundColor,
  borderRadius,
  'data-testid': testId,
  fade,
  hoverSrc = ``,
  interactive,
  metadata,
  objectFit,
  overlay,
  padding,
  onLoad = () => {},
  role,
  src = ``,
  style = {},
}) => {
  const [isVisible, setIsVisible] = React.useState(Boolean(!metadata))
  const [loadedImage, setLoadedImage] = React.useState(false)

  const outerStyle = {
    backgroundColor: backgroundColor || (!loadedImage && `var(--skeletonColor)`),
    borderRadius,
    padding,
  }

  return (
    <ImpressionObserver
      onUniqueImpression={() => {
        setIsVisible(true)
      }}
      options={{
        threshold: 0.25,
      }}
    >
      <div
        className={classNames({
          [styles.overlay]: overlay,
          [styles.fade]: fade,
          [styles.interactive]: interactive,
        })}
        style={outerStyle}
      >
        <AspectRatio
          metadata={metadata}
          objectFit={objectFit}
        >
          <ErrorBoundary
            errorComponent={TextError}
            errorTitle="HtmlText error"
            severity={2}
          >
            {isVisible &&
              <ImageInner
                alt={alt}
                data-testid={testId}
                handleImageLoad={() => {
                  setLoadedImage(true)
                  onLoad()
                }}
                metadata={metadata}
                role={role}
                src={src}
                style={style}
              />
            }
            {
              hoverSrc && (
                <ImageInner
                  alt={alt}
                  className={styles.hoverImage}
                  data-testid={testId}
                  metadata={metadata}
                  role={role}
                  src={hoverSrc}
                  style={{
                    position: `absolute`,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    ...style,
                  }}
                />
              )
            }
          </ErrorBoundary>
        </AspectRatio>
      </div>
    </ImpressionObserver>
  )
}
