/* eslint-disable space-before-function-paren */
import isEqual from 'lodash/isEqual'

import * as sessionApi from 'services/api/session'
import { adyenDeviceFingerprint } from 'utils'
import { getSelectedShippingAddress } from 'utils/getSelectedShippingAddress'
import { updateGoogleAnalytics } from 'services/analytics'
import { normalizeCartResponse } from 'models/cart-information'

import {
  ADD_CART_ITEM,
  APPLY_VOUCHER,
  DISABLE_CHECKOUT_SUBMIT,
  ENABLE_CHECKOUT_SUBMIT,
  FINGERPRINT_DEVICE,
  LOAD_CART_CONTENTS,
  RECREATE_CART,
  REMOVE_REJECTION_ERROR,
  REMOVE_VOUCHER_MESSAGE,
  REMOVE_VOUCHER,
  RESTART_SESSION,
  SAVE_SHIPPING_SELECTION,
  SEND_CONSUMER_PROPERTIES,
  SET_TAX_ERROR,
  SET_TAX_LOADING,
  SET_UNABLE_TO_SHIP_ERROR,
  START_EXTERNAL_PAYMENT_METHOD,
  UPDATE_AGREEMENT,
  UPDATE_CART_ITEM,
  UPDATE_CONSUMER_DETAILS,
} from 'state/actions'
import {
  updateSelectedBillingAddress,
  updateSelectedShippingAddress,
  resetDefaultShippingAddress,
} from 'state/actions/addresses'
import { clearMessages } from 'state/actions/messages'
import { setV2CaptchaId } from 'state/actions/site'

import {
  ADDRESS_SOURCE_CART_API,
  CAPTCHA_TRIGGER,
} from 'utils/constants'
import { utilizeUserAuth } from 'global-content/utils'
import { getMetaOptions } from 'global-content/config'
import * as api from 'services/api'
import { constructConsumer } from 'services/construct-order-payload'

export const disableCheckoutSubmit = () => ({
  type: DISABLE_CHECKOUT_SUBMIT,
})

export const enableCheckoutSubmit = () => ({
  type: ENABLE_CHECKOUT_SUBMIT,
})

export const fingerprintDevice = (elementId) => ({
  type: FINGERPRINT_DEVICE,
  payload: adyenDeviceFingerprint(elementId),
})

export const updateAgreement = (payload) => ({
  type: UPDATE_AGREEMENT,
  payload,
})

export const updateConsumerDetails = (payload) => ({
  type: UPDATE_CONSUMER_DETAILS,
  payload,
})

export const startExternalPaymentMethod = (brandCode, captchaId, captchaToken, captchaType) => async (dispatch) => {
  dispatch(clearMessages({ type: `checkout` }))
  dispatch(disableCheckoutSubmit())
  dispatch({
    type: `${START_EXTERNAL_PAYMENT_METHOD}_PENDING`,
  })

  const response = await api.checkout({
    paymentMethod: brandCode,
    userAgent: window.navigator.userAgent,
  }, {
    captchaToken,
    captchaType,
  })

  if (response === CAPTCHA_TRIGGER) {
    return dispatch(setV2CaptchaId(captchaId))
  }

  return dispatch({
    type: `${START_EXTERNAL_PAYMENT_METHOD}_FULFILLED`,
    payload: response,
  })
}

// Payment
export const loadCartContents = () => async (dispatch, getState) => {
  const state = getState()
  const userAuthAvailable = state.site.userAuthAvailable
  const selectedShippingAddress = getSelectedShippingAddress(state)
  const selectedBillingAddress = state.addresses.selectedBillingAddress
  dispatch(disableCheckoutSubmit())

  if (!userAuthAvailable && getMetaOptions(`integrations.cognito.enabled`)) {
    await utilizeUserAuth()
  }

  dispatch({
    type: LOAD_CART_CONTENTS,
    payload: api.loadCartContents()
      .then(response => {
        const normalizedResponse = normalizeCartResponse(response)

        const {
          BILLING,
          DELIVERY,
        } = normalizedResponse.addresses

        dispatch(resetDefaultShippingAddress())

        if (BILLING && !isEqual(BILLING, selectedBillingAddress) && !doesBillingMatchShipping({ BILLING, DELIVERY })) {
          dispatch(updateSelectedBillingAddress({
            address: BILLING,
            source: ADDRESS_SOURCE_CART_API,
          }))
        }

        if (DELIVERY && !isEqual(DELIVERY, selectedShippingAddress)) {
          dispatch(updateSelectedShippingAddress({
            address: DELIVERY,
            source: ADDRESS_SOURCE_CART_API,
          }))
        }

        return normalizedResponse
      }),
  })
}

export const addCartItem = ({
  customisation,
  quantity,
  product,
  skuOptions,
  atcAction,
  buttonType,
  buttonAnchorPosition,
  pageArea,
}) => {
  return ({
    type: ADD_CART_ITEM,
    meta: {
      atcAction,
    },
    payload: api.addCartItem({ quantity, product, skuOptions, customisation })
      .then(response => ({
        cartResponse: normalizeCartResponse(response),
        product,
        skuOptions,
        quantity,
        atcAction,
        buttonType,
        buttonAnchorPosition,
        pageArea,
      }))
      .then(handleAnalytics),
  })
}

export const updateCartItem = ({
  cartItem,
  checkoutStep,
  quantity,
  atcAction,
  buttonType,
  buttonAnchorPosition,
  pageArea,
}) => ({
  type: UPDATE_CART_ITEM,
  meta: {
    atcAction,
  },
  payload: api.updateCartItem({ cartItem, quantity })
    .then(response => ({
      cartResponse: normalizeCartResponse(response),
      cartItem,
      checkoutStep,
      quantity,
      atcAction,
      buttonType,
      buttonAnchorPosition,
      pageArea,
    }))
    .then(handleAnalytics),
})

export const recreateCart = ({ cartPublicId }) => ({
  type: RECREATE_CART,
  payload: api.recreateCart({ cartPublicId })
    .then(response => normalizeCartResponse(response)),
})

export const setTaxLoading = (isLoading) => ({
  type: SET_TAX_LOADING,
  payload: isLoading,
})

export const setTaxError = (isError) => ({
  type: SET_TAX_ERROR,
  payload: isError,
})

export const setUnableToShipError = (isError) => ({
  type: SET_UNABLE_TO_SHIP_ERROR,
  payload: isError,
})

// Vouchers
export const applyVoucher = (voucher) => ({
  type: APPLY_VOUCHER,
  payload: api.applyVoucher(voucher)
    .then(response => normalizeCartResponse(response)),

})

export const removeVoucher = voucher => ({
  type: REMOVE_VOUCHER,
  payload: api.removeVouchers(voucher)
    .then(response => normalizeCartResponse(response)),
})

export const removeVoucherMessage = () => ({
  type: REMOVE_VOUCHER_MESSAGE,
})

export const updateShippingSelection = (id) => async (dispatch) => {
  dispatch(disableCheckoutSubmit())
  await dispatch(saveShippingSelection(id))
  dispatch(loadCartContents())
}

const saveShippingSelection = (id) => ({
  type: SAVE_SHIPPING_SELECTION,
  payload: api.saveShippingOption(id),
})

function handleAnalytics(response) {
  const {
    cartItem, // updateCartItem
    cartResponse,
    checkoutStep, // in checkout
    product, // addCartItem
    quantity,
    skuOptions, // addCartItem
    atcAction, // Multiple Cart Buttons
    buttonType, // Button position on page
    buttonAnchorPosition, // Button location on viewport
    pageArea, // Area of page where button is shown
  } = response

  const data = {
    cartItem,
    product,
    skuOptions,
    quantity,
    atcAction,
    buttonType,
    buttonAnchorPosition,
    pageArea,
  }

  if (checkoutStep) {
    updateGoogleAnalytics(`checkout`, {
      items: cartResponse.items,
      step: checkoutStep,
    })
  }

  if (atcAction === `primary`) {
    if (quantity === 0) {
      updateGoogleAnalytics(`removeFromCart`, data)
    } else {
      updateGoogleAnalytics(`addToCart`, data)
    }
  }

  // return response to resolve actions
  return response
}

export const sendConsumerProperties = () => async (dispatch, getState) => {
  const {
    account,
    addresses,
    cart,
  } = getState()

  const { selectedShippingAddress } = addresses
  const { consumer } = cart
  const { properties } = constructConsumer(consumer, selectedShippingAddress)

  if (!account.isUserSignedIn) {
    return {
      type: SEND_CONSUMER_PROPERTIES,
      payload: api.sendConsumerProperties(properties),
    }
  }
}

function doesBillingMatchShipping({
  BILLING,
  DELIVERY,
}) {
  const matchingPartsBilling = {
    addressLine1: BILLING.addressLine1,
    addressLine2: BILLING.addressLine2,
    city: BILLING.city,
    postcode: BILLING.postcode,
    region: BILLING.region,
    territoryCode: BILLING.territoryCode,
  }

  const matchingPartsShipping = {
    addressLine1: DELIVERY.addressLine1,
    addressLine2: DELIVERY.addressLine2,
    city: DELIVERY.city,
    postcode: DELIVERY.postcode,
    region: DELIVERY.region,
    territoryCode: DELIVERY.territoryCode,
  }

  return isEqual(matchingPartsBilling, matchingPartsShipping)
}

export const restartSession = () => ({
  type: RESTART_SESSION,
  payload: sessionApi.restartSession(),
})

export const removeRejectionError = () => ({
  type: REMOVE_REJECTION_ERROR,
})
