import React, { useRef, useState, useEffect } from 'react'
import Player from "@vimeo/player"
import { reportError } from 'services/reportError'
import { getMetaOptions } from 'global-content/config'
import { CircularLoader } from 'components/CircularLoader'
import { Script } from 'factory-components/Script'
import { Svg } from 'components/Svg'

import styles from './Video.css'
import { AspectRatio } from 'components/AspectRatio'
import { addQueryParametersToUrlString } from 'utils/addQueryParametersToUrlString'

export const Video = (props) => {
  const {
    'data-testid': testId,
    src,
  } = props

  if (src.includes(`player.vimeo.com/video/`)) {
    return (
      <VimeoVideo
        {...props}
        testId={testId}
      />
    )
  }

  return (
    <EmbeddedVideo
      {...props}
      isHls={src.endsWith(`.m3u8`)}
      testId={testId}
    />
  )
}

const EmbeddedVideo = ({
  isHls,
  metadata,
  muted,
  objectFit,
  play,
  poster,
  src,
  style,
  testId,
}) => {
  const [canplaythrough, setCanplaythrough] = React.useState(false)
  const [buffering, setBuffering] = React.useState(0)
  const [showLoader, setShowLoader] = React.useState(false)
  const [hlsLoaded, setHlsLoaded] = React.useState(false)
  const videoRef = React.useRef()

  const updateBuffer = React.useCallback(() => {
    if (videoRef.current) {
      const {
        buffered,
        duration,
      } = videoRef.current

      if (buffered.length) {
        setBuffering(Math.ceil((buffered.end(0) / duration) * 100))
      }
    }
  }, [])

  const canPlayThrough = React.useCallback(() => {
    if (showLoader) {
      clearTimeout(this.showLoader)
    }
    setCanplaythrough(true)
    setShowLoader(false)
  }, [])

  React.useEffect(() => {
    videoRef.current.load()
  }, [])

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener(`progress`, updateBuffer)
      videoRef.current.addEventListener(`canplaythrough`, canPlayThrough)
    }

    if (play && canplaythrough) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(`progress`, updateBuffer)
        videoRef.current.removeEventListener(`canplaythrough`, canPlayThrough)
      }
    }
  }, [play, canplaythrough, videoRef.current])

  React.useEffect(() => {
    let hls
    if (hlsLoaded && window.Hls) {
      if (window.Hls.isSupported()) {
        hls = new window.Hls()
        hls.attachMedia(videoRef.current)
        hls.on(window.Hls.Events.MEDIA_ATTACHED, () => {
          hls.loadSource(src)
        })
      }
    }
    return () => {
      if (hlsLoaded && hls) {
        hls.destroy()
      }
    }
  }, [hlsLoaded])

  return (
    <div
      className={styles.container}
      data-testid={testId}
    >
      {isHls &&
        <Script
          data={{
            content: {
              scripts: [`https://cdn.jsdelivr.net/npm/hls.js@latest`],
            },
          }}
          onLoad={() => setHlsLoaded(true)}
        />
      }
      <AspectRatio
        metadata={metadata}
        objectFit={objectFit}
      >
        <video
          loop
          muted={muted}
          playsInline
          poster={poster}
          preload="auto"
          ref={videoRef}
          style={style}
        >
          <source src={src} />
        </video>
      </AspectRatio>
      {showLoader &&
        <div
          className={styles.buffering}
        >
          <div className={styles.loader}>
            {buffering}
%
            <div className={styles.loader}>
              <CircularLoader
                percentage={buffering}
                placeholder
                size={8}
              />
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const VimeoVideo = ({
  src,
  width,
  height,
  fullWidth,
  testId,
  hasAudio,
  hasControls,
  poster,
}) => {
  const initAutoplay = src.includes(`autoplay=true`) || src.includes(`autoplay=1`)
  const initBackground = src.includes(`background=1`) || src.includes(`background=true`)
  const initMuted = src.includes(`muted=true`) || src.includes(`muted=1`)
  const [isLoaded, setIsLoaded] = useState(false)
  const [show, setShow] = useState(false)
  const [isPlaying, setIsPlaying] = useState(initAutoplay || initBackground)
  const [isMuted, setIsMuted] = useState(initMuted || initBackground)
  const player = useRef()
  const frame = useRef()

  src = addQueryParametersToUrlString(src, {
    dnt: 1,
  })

  function loadedVideo() {
    if (frame.current && !player.current) {
      try {
        player.current = new Player(frame.current)
        player.current.on(`play`, () => {
          setShow(true)
        })
        player.current.on(`loaded`, () => {
          setIsLoaded(true)
        })
      } catch (e) {
        reportError(e)
      }
    }
  }

  useEffect(() => {
    loadedVideo()
  }, [frame.current])

  return (
    <div
      className={`${styles.container} ${fullWidth ? styles.fullContainer : ``}`}
      data-testid={testId}
      style={{
        paddingTop: fullWidth ? `${Math.round((height / width) * 100)}%` : undefined,
        height: fullWidth ? undefined : height,
        width: fullWidth ? undefined : width,
        backgroundImage: poster && !show
          ? `url(${poster.startsWith(`http`) ? `` : getMetaOptions(`countryFolder`)}${poster})`
          : undefined,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
      }}
    >
      <iframe
        allow="autoplay"
        allowFullScreen
        className={styles.iframeVideo}
        data-ot-ignore
        id="embeddedVideo"
        ref={frame}
        src={src}
        style={{
          backgroundColor: `transparent`,
        }}
      />

      {isLoaded && hasControls &&
        (<div className={styles.iframeVideoControls}>
          <button
            className={styles.iframVideoControl}
            onClick={() => {
              if (isPlaying) {
                player.current.pause()
                setIsPlaying(false)
              } else {
                player.current.play()
                setIsPlaying(true)
              }
            }}
          >
            <Svg
              icon={isPlaying ? `pause-filled` : `play-filled`}
              size="3.5rem"
            />
          </button>
          {hasAudio && (
            <button
              className={styles.iframVideoControl}
              onClick={() => {
                if (isMuted) {
                  player.current.setVolume(1)
                  setIsMuted(false)
                } else {
                  player.current.setVolume(0)
                  setIsMuted(true)
                }
              }}
            >
              <Svg
                icon={isMuted ? `mute` : `sound`}
                size="3.5rem"
              />
            </button>
          )}
        </div>)
      }
    </div>
  )
}
