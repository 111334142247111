import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { updateGoogleAnalytics } from 'services/analytics'
import { PageTemplate } from 'components/PageTemplate'
import { PageBody } from 'components/PageBody'
import { Factory } from 'components/Factory'
import { useRouteChange } from 'hooks/useRouteChange'
import { useRouterRedirectList } from 'hooks/useRouterRedirectList'
import { Events } from 'design-system/services/events'
import styles from './NotFound.css'

export const NotFound = () => {
  const {
    pathname,
    search,
  } = useLocation()
  const updateLocation = useRouteChange()
  const redirectTo = useRouterRedirectList()

  React.useEffect(() => {
    // this updateLocation is for when the PDP fails to return a product
    updateLocation({
      force: true,
      pathname,
      scrollToY: 0,
      search,
    })

    updateGoogleAnalytics(`pageNotFound`, {
      url: pathname,
    })
    updateGoogleAnalytics(`virtual_pageview_pageNotFound`)
    Events.emitSoft404Page()
  }, [])


  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return (
    <PageTemplate
      template="status-404"
    >
      {NotFoundComponent}
    </PageTemplate>
  )
}

const NotFoundComponent = ({
  items,
  ...props
}) => {
  return (
    <PageBody
      className={styles.notFound}
      data-testid="not-found"
      {...props}
    >
      <Factory items={items} />
    </PageBody>
  )
}
