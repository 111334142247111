import React from 'react'
import ReactDom from 'react-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useLockBodyScroll } from 'hooks/useLockBodyScroll'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useKeyPress } from 'hooks/useKeyPress'
import { Svg } from 'components/Svg'
import { SCAFFOLD_ID } from 'components/Scaffold/fixtures'

import styles from './Modal.css'

export const Modal = ({
  className,
  children,
  closeModal,
  mobileFullScreen,
}) => {
  const accessibilityMode = useSelector(state => state.site.accessibilityMode)
  const originalFocus = document.activeElement
  const ref = React.useRef()
  useLockBodyScroll()
  useOnClickOutside(ref, closeModal)

  React.useEffect(() => {
    trapFocus()

    return () => {
      untrapFocus()
      originalFocus.focus()
    }
  }, [])

  if (useKeyPress(`Escape`)) {
    closeModal()
  }

  return ReactDom.createPortal(
    <div
      className={classNames(styles.modal, className, {
        [styles.accessibilityMode]: accessibilityMode,
        [styles.mobileFullScreen]: mobileFullScreen,
      })}
      data-testid="modal"
    >
      <div
        className={styles.contentWrapper}
        ref={ref}
      >
        {closeModal &&
          <button
            className={styles.close}
            onClick={() => closeModal()}
          >
            <Svg
              icon="close"
              size="2rem"
            />
          </button>
        }
        <div className={classNames(styles.content)}>
          {children}
        </div>
      </div>
    </div>,
    document.getElementById(`modal`)
  )
}

function trapFocus() {
  document.getElementById(SCAFFOLD_ID).setAttribute(`aria-hidden`, `true`)
}

function untrapFocus() {
  const scaffold = document.getElementById(SCAFFOLD_ID)

  if (scaffold) {
    document.getElementById(SCAFFOLD_ID).removeAttribute(`aria-hidden`)
  }
}
