import React from 'react'
import ReactDOM from 'react-dom'

import store from 'state/reducers'
import { loadedDependencies, detectCountry } from 'state/actions/site'
import Login from 'utils/login'
import ShoppingSessionId from 'utils/ShoppingSessionId'
import { getLanguage } from 'utils'
import { tokenCookie } from 'utils/tokenCookie'
import {
  getConfigData,
  //getMetaData,
  getSortOptionsData,
  getMetaDataV2,
} from 'utils/getMetaData'
import { setGlobalConfig, getMetaOptions } from 'global-content/config'
import { checkForPreviewMode } from 'global-content/preview'
import { contentDependencies } from 'global-content/contentDependencies'
import { setAlgoliaIndices } from 'global-content/algolia'
import { setComponents } from 'global-content/components'
import { setShippingOptions } from 'global-content/shippingOptions'
import { setGlobalUtils } from 'global-content/utils'
import { setPrefixedUrl } from 'utils/setPrefixedUrl'
import { getCacheBust } from 'utils/getCacheBust'
import { redirectToHttps } from 'utils/redirectToHttps'
import { App } from 'app'
import { AppError } from 'components/AppError'
import { setupLazySentry } from 'utils/setupLazySentry'

import 'css/reset.css'
import 'css/general.css'

// to polyfill Promise.allSettled
import PromiseAllSettled from 'core-js/stable/promise/all-settled.js'
if (Promise && Boolean(Promise.allSettled) === false) {
  Promise.allSettled = PromiseAllSettled
}

redirectToHttps()
const container = document.getElementById(`root`)

// this if/else is to support hot-reloading correctly as main is the entry point
if (!window.$META) {
  // reset onerror
  if (window.onerror) {
    window.showUnsupportedFn = window.onerror
    window.onerror = null
  }

  window.$shoppingSessionId = new ShoppingSessionId()
  window.$shoppingSessionId.init()
  window.$cacheBust = getCacheBust()
  window.$I18n = {
    languageMap: {},
    replaceMap: {},
  }

  checkForPreviewMode()

  if (window.$MSWMODE) {
    import(`../utils/msw/setupMsw`)
  }

  // This is temporary code to help Laviniu and Leon
  tokenCookie()

  Promise.all([
    //getMetaData(),
    getMetaDataV2()
      .catch((err) => {
        err.additional = {
          source: `main`,
        }
        throw err
      }),
    getConfigData()
      .catch((err) => {
        err.additional = {
          source: `main`,
        }
        throw err
      }),
    getSortOptionsData()
      .catch((err) => {
        console.warn(err)
        return {}
      }),
    // eslint-disable-next-line
  ]).then(async ([
    //meta,
    metaV2,
    config,
    sortOptions = {},
  ]) => {
    window.$HISTORY = []
    window.$META = {
      //...meta,
      ...metaV2,
    }

    // USE TO DEBUG COGNITO
    // window.$META.integrations.cognito = {
    //   appClientId: `17anjk1n12501ve7b92u88irij`,
    //   enabled: true,
    //   poolId: `us-east-1_xW6MCqtmU`,
    //   region: `us-east-1`,
    // }
    window.$content = {}
    const login = new Login()

    await login.init()

    setGlobalUtils()
    setGlobalConfig({ ...config, ...sortOptions })
    setPrefixedUrl()
    setAlgoliaIndices()
    setShippingOptions() // TODO: move to a separate JSON file / api call
    setComponents() // TODO: move to a separate JSON file / api call
    setupLazySentry(getMetaOptions(`integrations.sentry.key`))

    const language = getLanguage()

    // Dependencies before paint
    return contentDependencies(language)
      .then(() => {
        store.dispatch(loadedDependencies(language))
        if (
          window.location.hostname !== `localhost` &&
          (getMetaOptions(`environment`) === `production` || getMetaOptions(`environment`) === `staging`)
        ) {
          // Detect country based on cloudfront rules, meant to be used for country modal selector
          store.dispatch(detectCountry())
        }
        ReactDOM.render(<App />, container)
      })
  }).catch(err => {
    console.error(err)
    ReactDOM.render(<AppError error={err} />, container)
  })
} else {
  ReactDOM.render(<App />, container)
}

if (module.hot) {
  module.hot.accept()
}
