import uniq from 'lodash/uniq'
import get from 'lodash/get'

import {
  ADD_CART_ITEM,
  DETAILS_ADD_MESSAGE,
  DETAILS_PRELOAD,
  DETAILS_REMOVE_MESSAGE,
  ENABLE_CUSTOMISATION,
  GET_PRODUCT,
  POST_PRODUCT_CUSTOMIZATION,
  PRE_SELECT_PRODUCT_CUSTOMISATION,
  NO_PRODUCT,
  SET_PENDING_QUANTITY,
  UPDATE_SELECTED_OPTION,
  UPDATE_MISSING_LEVELS,
  VALIDATE_PRODUCT_CUSTOMISATION,
} from 'state/actions'
import { STATUSCODE_ADD_CART_FAILURE } from 'utils/constants'
import {
  getOptionValues,
  getSkuOptions,
} from 'state/utils/details'
import { getDetailsImages } from 'utils/getDetailsImage'


const initialCustomisationState = {
  hasPreview: false,
  loading: true,
  error: false,
  enabled: false,
  cart: undefined,
  imageSource: undefined,
  options: [],
  excludeSkus: [],
  selectedOptions: {},
  validation: {},
  customisedImage: {
    sources: {},
  },
}

const initialState = {
  infoMessages: [],
  levelOptions: {},
  loadingProduct: true,
  missingLevels: [],
  notFound: false,
  pendingQuantity: 1,
  product: undefined,
  plpInfo: {
    queryID: undefined,
    objectID: undefined,
    indexName: undefined,
    filters: [],
  },
  customisation: initialCustomisationState,
  selectedSlugs: {},
  skuOptions: {},
  promotions: {},
}

const details = (state = initialState, action) => {
  switch (action.type) {
  case `${ADD_CART_ITEM}_REJECTED`:
    return {
      ...state,
      infoMessages: uniq([...state.infoMessages, STATUSCODE_ADD_CART_FAILURE]),
    }
  case DETAILS_ADD_MESSAGE: {
    return {
      ...state,
      infoMessages: uniq([...state.infoMessages, action.payload]),
    }
  }
  case DETAILS_REMOVE_MESSAGE: {
    const infoMessages = state.infoMessages.filter(messageCode => messageCode !== action.payload)

    return {
      ...state,
      infoMessages,
    }
  }
  case SET_PENDING_QUANTITY: {
    return {
      ...state,
      pendingQuantity: action.payload,
    }
  }
  case UPDATE_SELECTED_OPTION: {
    const selectedSlugs = {
      ...state.selectedSlugs,
      ...action.payload.options,
    }
    const isValid = action.payload.isValid
    const levelOptions = getOptionValues(state.product, selectedSlugs)
    const skuOptions = getSkuOptions(levelOptions, selectedSlugs, isValid)
    const promotions = action.payload.getPromotions(state.product, skuOptions)

    // Note: When we change sizeType, we reset the currently selected size option
    if (action.payload.options.sizeType) {
      delete selectedSlugs.size
    }

    return {
      ...state,
      infoMessages: [],
      levelOptions,
      selectedSlugs,
      skuOptions,
      promotions,
    }
  }
  case UPDATE_MISSING_LEVELS: {
    return {
      ...state,
      missingLevels: action.payload,
    }
  }
  case `${GET_PRODUCT}_PENDING`: {
    return {
      ...state,
      customisation: initialCustomisationState,
      loadingProduct: true,
      notFound: false,
    }
  }
  case `${GET_PRODUCT}_FULFILLED`: {
    const {
      levelOptions,
      product,
      selectedSlugs,
      skuOptions,
      promotions,
    } = action.payload

    return {
      ...state,
      infoMessages: [],
      loadingProduct: false,
      levelOptions,
      notFound: false,
      pendingQuantity: 1,
      product,
      selectedSlugs,
      skuOptions,
      promotions,
    }
  }
  case`${POST_PRODUCT_CUSTOMIZATION}_REJECTED`: {
    const {
      images = [],
    } = getDetailsImages(state)
    return {
      ...state,
      customisation: {
        ...state.customisation,
        error: true,
        loading: false,
        imageSource: images[0],
      },
    }
  }
  case `${POST_PRODUCT_CUSTOMIZATION}_PENDING`: {
    return {
      ...state,
      customisation: {
        ...state.customisation,
        loading: true,
        error: false,
      },
    }
  }
  case `${POST_PRODUCT_CUSTOMIZATION}_FULFILLED`: {
    const hasPreview = action.payload.config.hasPreview
    const enabled = action.payload.success
    let imageSource = get(action.payload, `cart.display.previewImage.full`)
    if (!enabled || !hasPreview) {
      const {
        images = [],
      } = getDetailsImages(state)
      imageSource = images[0]
    }

    return {
      ...state,
      customisation: {
        ...state.customisation,
        cart: action.payload.cart,
        selectedOptions: action.payload.selectedOptions,
        ...action.payload.config,
        options: action.payload.config.options.map((option) => {
          return {
            ...option,
            defaultValue: option.defaultValue || option.values.find((value) => value.default).value,
          }
        }),
        enabled,
        error: false,
        loading: false,
        imageSource,
      },
    }
  }
  case PRE_SELECT_PRODUCT_CUSTOMISATION: {
    const {
      id,
      value,
    } = action.payload
    return {
      ...state,
      customisation: {
        ...state.customisation,
        loading: true,
        options: state.customisation.options.map((option) => {
          return {
            ...option,
            errors: [],
          }
        }),
        selectedOptions: {
          ...state.customisation.selectedOptions,
          [id]: value,
        },
      },
    }
  }
  case ENABLE_CUSTOMISATION: {
    return {
      ...state,
      customisation: {
        ...state.customisation,
        enabled: action.payload.enabled,
        loading: action.payload.enabled,
      },
    }
  }
  case VALIDATE_PRODUCT_CUSTOMISATION: {
    const {
      errors,
    } = action.payload
    const options = state.customisation.options.map((option) => {
      if (errors[option.id]) {
        return {
          ...option,
          errors: errors[option.id],
        }
      }
      return option
    })
    return {
      ...state,
      customisation: {
        ...state.customisation,
        options,
      },
    }
  }
  case DETAILS_PRELOAD: {
    const {
      indexName,
      product,
      selectedSlugs,
      filters,
      queryID,
    } = action.payload

    return {
      ...state,
      infoMessages: [],
      loadingProduct: true,
      notFound: false,
      pendingQuantity: 1,
      levelOptions: {
        color: formatPreloadOptions(product.options, product.id),
      },
      customisation: initialCustomisationState,
      plpInfo: {
        objectID: product.objectID,
        queryID,
        indexName,
        filters,
      },
      skuOptions: {
        availability: product.availability,
        price: product.price,
      },
      product,
      selectedSlugs,
    }
  }
  case NO_PRODUCT:
  case `${GET_PRODUCT}_REJECTED`:
    return {
      ...initialState,
      loadingProduct: false,
      notFound: true,
    }
  default:
    return state
  }
}

function formatPreloadOptions(options, productId) {
  // add productSlug to color options
  return options.map(option => ({
    ...option,
    productSlug: `${productId}.${option.slug}`,
  }))
}

export default details
