import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { I18n } from 'utils/i18n'
import { updateGoogleAnalytics } from 'services/analytics'
import { SiteSearch } from 'factory-components/SiteSearch'
import { Heading } from 'components/Heading'
import { webLabels } from './fixtures'
import styles from './EmptyListingFallback.css'

export const EmptyListingFallback = () => {
  const {
    pathname,
    search,
  } = useLocation()
  const { query } = queryString.parse(search)

  React.useEffect(() => {
    if (pathname === `/search`) {
      updateGoogleAnalytics(`emptySearch`, {
        query,
      })
    }
  }, [pathname, query])

  return (
    <section className={styles.container}>
      <div className={styles.textWrapper}>
        <Heading
          content={{
            html: I18n.t(webLabels.title),
          }}
          styling={{
            fontFamily: `primary`,
            fontSize: `medium`,
          }}
        />
        {I18n.t(webLabels.subtitle)}
      </div>
      <SiteSearch
        data={{
          positioning: {
            searchIconInside: `left`,
          },
          styling: {
            inputBorderSize: `1px`,
            inputBorderColor: `var(--greyLight)`,
          },
        }}
      />
    </section>
  )
}
