
export function extractSEORoutesFromNavTree(node) {
  const seoRoutes = {}

  if(node && typeof node === `object`){
    if(node.seo) {
      const path = node.path
      seoRoutes[path] = {
        ...node.seo,
      }
    }

    Object.values(node).forEach(value => {
      if (value && typeof value === `object`){
        Object.assign(seoRoutes, extractSEORoutesFromNavTree(value))
      }
    })
  }

  return seoRoutes
}
