import some from 'lodash/some'
import escapeRegExp from 'lodash/escapeRegExp'
import isString from 'lodash/isString'

export function wildcardMatch(pathname, patterns) {
  if (isString(patterns)) {
    patterns = [patterns]
  }

  return some(patterns, pattern => {
    // coverts a url with * wildcards into a regular expression that matches the entire string,
    // where * is replaced with "any characters" and special characters are safely escaped.
    const regexPattern = `^` + escapeRegExp(pattern).replace(/\\\*/g, `.*`) + `$`
    const regex = new RegExp(regexPattern)
    return regex.test(pathname)
  })
}

