import React from 'react'
import styles from './SectionLabel.css'
import { Factory } from 'components/Factory'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { getConfigOptions } from 'global-content/config'

export const SectionLabel = ({
  data = {},
  items,
  children,
}) => {
  const {
    content = {},
    styling = {},
    customClassName,
  } = data
  const {
    rightAlign,
    vertical,
  } = styling
  const {
    text,
  } = content

  const sizeOptions = useSelector(state => state.details.levelOptions.size || [])

  return showSection(items) && (
    <div
      className={
        classnames(
          customClassName,
          styles.section,
          {
            [styles.vertical]: vertical,
          }
        )
      }
    >
      {text && (
        <div className={styles.sectionLabel}>
          {text}
          :
        </div>
      )}
      <div
        className={
          classnames(
            styles.sectionContent,
            {
              [styles.right]: rightAlign,
            }
          )
        }
      >
        {items && items.length ? <Factory items={items} /> : null}
        {children}
      </div>
    </div>
  )

  function showSection(sectionItems) {
    if (sectionItems && sectionItems.length === 1 && sectionItems[0].data.component === `productOptions`) {
      const productOptions = sectionItems[0].data
      if (productOptions?.config?.color?.hide &&
        productOptions?.config?.sizeType?.hide
      ) {
        const hiddenSizes = getConfigOptions(`components.product.hiddenSizes`) || []
        if (sizeOptions.length === 1 && hiddenSizes.includes(sizeOptions[0].slug)) {
          return null
        }
      }
    }
    return true
  }
}
