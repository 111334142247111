import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { algoliaFiltersMap } from 'services/algolia'
import { I18n } from 'utils/i18n'
import { Button } from 'factory-components/Button'
import { Svg } from 'components/Svg'
import { Heading } from 'components/Heading'
import { Layout } from 'components/Layout'

import { webLabels } from './fixtures'
import styles from './FiltersMenuButton.css'

export const FiltersMenuButton = ({
  active,
  buttonType = `default`,
  children,
  className,
  closeIcon,
  fontSize,
  name,
  onClick,
  openIcon,
  padding,
  positioning,
}) => {
  const selectedFilters = useSelector(state => state.listing.selectedFilters)

  let filterType
  let webLabel = webLabels.sortByLabel

  if (name !== `sortBy`) {
    const FILTERSMAP = algoliaFiltersMap.get()
    webLabel = FILTERSMAP[name].webLabel
    filterType = FILTERSMAP[name].filterType
  }

  return (
    <Layout
      className={styles.container}
      layout={{
        paddingHorizontal: padding,
      }}
    >
      <Button
        aria-label={I18n.t(webLabel)}
        aria-pressed={active ? `true` : `false`}
        className={classNames(styles.button, {
          [className]: className,
          [styles.active]: active,
        })}
        data={{
          positioning: positioning || getDefaultPositioning(),
          styling: {
            type: buttonType,
          },
        }}
        name={name}
        onClick={onClick}
      >
        <div className={styles.headingWrapper}>
          {webLabel &&
            <Heading
              config={{
                elementTag: `h4`,
              }}
              content={{
                html: I18n.t(webLabel),
              }}
              styling={{
                fontFamily: `secondary`,
                fontSize,
                universalColor: true,
              }}
            />
          }
          {selectedFilters[name] && Boolean(selectedFilters[name].length) &&
            <span
              className={classNames(styles.besideLabel, { // universal text component needed
                [styles.xxl]: fontSize === `xxl`,
                [styles.xl]: fontSize === `xl`,
                [styles.large]: fontSize === `large`,
                [styles.medium]: fontSize === `medium`,
                [styles.small]: fontSize === `small`,
                [styles.xs]: fontSize === `xs`,
              })}
            >
              {renderBesideLabel()}
            </span>
          }
        </div>
        <Svg
          icon={active ? openIcon : closeIcon}
          size="1.4rem"
        />
      </Button>
      {children}
    </Layout>
  )

  function getDefaultPositioning() {
    return buttonType === `secondary` && {
      buttonHeight: `medium`,
    }
  }

  function renderBesideLabel() {
    if (filterType === `numeric`) {
      return (
        <Svg
          icon="check"
          size="1.2rem"
        />
      )
    }

    return (
      <span>
        (
        {selectedFilters[name].length}
        )
      </span>
    )
  }
}
